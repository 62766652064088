import { Wrapper, Grid, Item, Label, TextBox, TagBox, CheckBox, Buttons, CheckBoxWrapper } from '../../BasicInformation.style';
import { MAX_WIDTH, REFERRAL_INFO, GRID_XS, GRID_SM } from '../../../../../../utils/constants';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

export const ReferralInfo = ({
	showButton,

	referralStatuses,

	getEntityPatientProfileReferralInfo,
	onChangeFunc,

	onCancelReferralInfo,
	onSaveReferralInfo,
}) => {
	return (
		<Wrapper id={REFERRAL_INFO} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityPatientProfileReferralInfo('loading')}
				position={{ of: `#${REFERRAL_INFO}` }} />
			{
				!getEntityPatientProfileReferralInfo('loading') &&
				<Grid container spacing={2}>
					<Item item sm={GRID_XS} md={GRID_SM}>
						<Tooltip title="Referral Information">
							<Label>Referral Information:</Label>
						</Tooltip>
						<TagBox
							label="Referral Information"
							valueExpr="Oid"
							displayExpr="Name"
							defaultValue={getEntityPatientProfileReferralInfo('referralInfo')}
							dataSource={referralStatuses}
							onValueChanged={e => onChangeFunc('ReferralInfo', e)}
							maxDisplayedTags={1}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true} />
					</Item>
					{
						!!getEntityPatientProfileReferralInfo('referralInfo') && getEntityPatientProfileReferralInfo('referralInfo').includes(referralStatuses.find((e) => e?.Name === "Other")?.Oid) &&
						<Item item sm={GRID_XS} md={GRID_SM}>
							<Tooltip title="Specify Referral Source">
								<Label>Specified Referral Source:</Label>
							</Tooltip>
							<TextBox
								label="Specify Referral Source"
								value={getEntityPatientProfileReferralInfo('otherReferralInfo')}
								onValueChanged={e => onChangeFunc('OtherReferralInfo', e, true)}
								maxLength={140}
								labelMode="hidden"
								stylingMode="underlined"
								inputAttr={{ autocomplete: 'new-otherReferralInfo' }}
							/>
						</Item>
					}
					<Item item sm={GRID_XS} md={GRID_SM}>
						<Tooltip title="Contact by Medical RN needed">
							<Label>Contact by Medical RN needed:</Label>
						</Tooltip>
						<CheckBoxWrapper>
							<CheckBox
								value={getEntityPatientProfileReferralInfo('refferalForMedicalRN')}
								onClick={() => onChangeFunc('RefferalForMedicalRN')} >
								{getEntityPatientProfileReferralInfo('refferalForMedicalRN') ? 'Yes' : 'No'}
							</CheckBox>
						</CheckBoxWrapper>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM}>
						<Tooltip title="Referral for a physical exam needed">
							<Label>Referral for a physical exam needed:</Label>
						</Tooltip>
						<CheckBoxWrapper>
							<CheckBox
								value={getEntityPatientProfileReferralInfo('contactedByPhysicalExam')}
								onClick={() => onChangeFunc('ContactedByPhysicalExam')} >
								{getEntityPatientProfileReferralInfo('contactedByPhysicalExam') ? 'Yes' : 'No'}
							</CheckBox>
						</CheckBoxWrapper>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM}>
						<Tooltip title="Reason you are seeking Behavioral Health services today">
							<Label>Reason you are seeking Behavioral Health services today:</Label>
						</Tooltip>
						<TextBox
							label="Reason you are seeking Behavioral Health services today:"
							value={getEntityPatientProfileReferralInfo('reason')}
							onValueChanged={e => onChangeFunc('Reason', e, true)}
							maxLength={140}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-reason' }}
						/>
					</Item>
					<Item item xs={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={REFERRAL_INFO}
								onClick={onSaveReferralInfo} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelReferralInfo} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper >
	);
};