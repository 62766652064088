import { isAdmin } from '../../../core/auth/authProvider';
//import { CURRENT_DATE, MAX_CURRENT_DATE, MAX_DATE, MAX_WIDTH, MIN_CURRENT_DATE, MIN_DATE, MAX_DOB, TEXT_DOB, ZIP_MASK, PHONE_MASK, GRID_XS, GRID_SM, GRID_MD } from '../../../utils/constants';
import { MAX_CURRENT_DATE, MAX_DATE, MAX_WIDTH, MIN_CURRENT_DATE, MIN_DATE, ZIP_MASK, PHONE_MASK, GRID_XS, GRID_SM, GRID_MD } from '../../../utils/constants';
import { Wrapper, TitleWrapper, Title, SubTitle, Text, Fieldset, Buttons, CheckboxGrid } from '../../../components/Main/Main.style';
import { Signature } from '../../../components/Signature/Signature.style';
import { dateFormatFromApi } from '../../../utils/formats';
import { onKeyDownHandle } from '../../../utils/helper';
import { CITY_RULE, NAME_RULE } from '../../../utils/rules';
import SignatureCanvas from 'react-signature-canvas';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import DateBox from 'devextreme-react/date-box';
import CheckBox from 'devextreme-react/check-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	PatternRule,
	CustomRule,
	RangeRule,
} from 'devextreme-react/validator';

export const ConsentForRelease = ({
	loading,
	error,

	states,

	decline,
	firstName,
	middleName,
	lastName,
	dob,
	address,
	city,
	state,
	zip,
	phone,
	legalGuardian,

	atkaHealthClinic,
	anchorageBehavioralHealth,
	nikolskiHealthCenter,
	saintGeorgeHealthCenter,
	oonalaskaPrimaryCare,
	oonalaskaBehavioralHealth,

	releaseInformationTo,
	obtainInformationFrom,
	verbally,
	inWritingElectronically,

	agency,
	agencyName,
	agencyAddress,
	agencyCity,
	agencyState,
	agencyZip,
	agencyPhone,

	assessmentRecommendations,
	admissionSummary,
	resultsOfUrinalysis,
	attendanceCompliance,
	treatmentPlan,
	psychiatricEvaluations,
	treatmentStatus,
	dischargeDateAndSummary,
	bhMedicationNeeds,
	regardingOther,
	regardingOtherText,

	referralTreatmentPlacement,
	provideReferralInformation,
	verifyParticipationInTreatment,
	coordinationOfServices,
	purposesOther,
	purposesOtherText,

	printedName,
	signedDate,
	expiredDate,

	onToggleDecline,
	onChangeFirstName,
	onChangeMiddleName,
	onChangeLastName,
	onChangeDOB,
	onChangeAddress,
	onChangeCity,
	onChangeState,
	onChangeZip,
	onChangePhone,
	onChangeLegalGuardian,

	onToggleAtkaHealthClinic,
	onToggleAnchorageBehavioralHealth,
	onToggleNikolskiHealthCenter,
	onToggleSaintGeorgeHealthCenter,
	onToggleOonalaskaPrimaryCare,
	onToggleOonalaskaBehavioralHealth,

	onToggleReleaseInformationTo,
	onToggleObtainInformationFrom,
	onToggleVerbally,
	onToggleInWritingElectronically,

	onChangeAgency,
	onChangeAgencyName,
	onChangeAgencyAddress,
	onChangeAgencyCity,
	onChangeAgencyState,
	onChangeAgencyZip,
	onChangeAgencyPhone,

	onToggleAssessmentRecommendations,
	onToggleAdmissionSummary,
	onToggleResultsOfUrinalysis,
	onToggleAttendanceCompliance,
	onToggleTreatmentPlan,
	onTogglePsychiatricEvaluations,
	onToggleTreatmentStatus,
	onToggleDischargeDateAndSummary,
	onToggleBhMedicationNeeds,
	onToggleRegardingOther,
	onChangeRegardingOtherText,

	onToggleReferralTreatmentPlacement,
	onToggleProvideReferralInformation,
	onToggleVerifyParticipationInTreatment,
	onToggleCoordinationOfServices,
	onTogglePurposesOther,
	onChangePurposesOtherText,

	onChangePrintedName,
	onChangeSignedDate,
	onChangeExpiredDate,

	onInitSignature,
	onChangeSignature,
	onClearSignature,
	onSaveConsentForRelease,
	onClickBack,
}) => {
	const sectionName = "consentForRelease";

	return (
		<Wrapper id={sectionName} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${sectionName}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<TitleWrapper>
							{isAdmin() && <Button hint='Go back to forms' icon="back" onClick={onClickBack} />}
							<Tooltip title="Consent for Release of Confidential Information">
								<Title>Consent for Release of Confidential Information</Title>
							</Tooltip>
						</TitleWrapper>
					</Grid>
					<CheckboxGrid container item spacing={2}>
						<CheckboxGrid item>
							<Tooltip title="This form is required. Check this checkbox if you REFUSE Telehealth Consent.">
								<Text>This form is <span style={{ color: 'var(--green-text-color)' }}>optional</span>. Check this checkbox if you DON’T WANT to share information with another agency, person, etc.</Text>
							</Tooltip>
						</CheckboxGrid>
						<CheckboxGrid item>
							<CheckBox
								value={decline}
								onValueChanged={onToggleDecline} />
						</CheckboxGrid>
					</CheckboxGrid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="First Name *">
							<>
								<TextBox
									label="First Name *"
									value={firstName}
									onValueChanged={onChangeFirstName}
									disabled={decline}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-firstName' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="First Name is required"
										/>
										<PatternRule
											message="Do not use digits in the First Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Middle Name">
							<>
								<TextBox
									label="Middle Name"
									value={middleName}
									onValueChanged={onChangeMiddleName}
									disabled={decline}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-middleName' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Last Name *">
							<>
								<TextBox
									label="Last Name *"
									value={lastName}
									onValueChanged={onChangeLastName}
									disabled={decline}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-lastName' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Last Name is required"
										/>
										<PatternRule
											message="Do not use digits in the Last Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Date of Birth *">
							<>
								<DateBox
									type="date"
									label="Date of Birth *"
									value={dateFormatFromApi(dob)}
									onValueChanged={onChangeDOB}
									disabled={decline}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									{/* <Validator validationGroup={sectionName}>
										<RequiredRule
											message="Date of birth is required"
										/>
										<RangeRule
											message="Date cannot be younger than the current date and earlier than 1900"
											min={MIN_DATE}
											max={CURRENT_DATE} />
										<RangeRule
											message={TEXT_DOB}
											max={MAX_DOB} />
									</Validator> */}
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Address">
							<>
								<TextBox
									label="Address"
									value={address}
									onValueChanged={onChangeAddress}
									disabled={decline}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-address' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="City">
							<>
								<TextBox
									label="City"
									value={city}
									onValueChanged={onChangeCity}
									disabled={decline}
									maxLength={20}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-city' }}>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="City is required"
										/>
										<PatternRule
											message="Do not use digits in the City"
											pattern={CITY_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="State">
							<>
								<SelectBox
									label="State"
									defaultValue={state}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={onChangeState}
									disabled={decline}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-state' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Zip">
							<>
								<TextBox
									label="Zip"
									value={zip}
									onValueChanged={onChangeZip}
									disabled={decline}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={ZIP_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="Zip must have at least 5 symbols"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-zip' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Phone *">
							<>
								<TextBox
									label="Phone *"
									value={phone}
									onValueChanged={onChangePhone}
									disabled={decline}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }}>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Phone is required"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_SM}>
						<Tooltip title="Parent/Legal Guardian *">
							<>
								<TextBox
									label="Parent/Legal Guardian *"
									value={legalGuardian}
									onValueChanged={onChangeLegalGuardian}
									disabled={decline}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-legalGuardian' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Parent/Legal Guardian is required"
										/>
										<PatternRule
											message="Do not use digits in the Parent/Legal Guardian"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<SubTitle disabled={decline}>Consent</SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={decline}><b>I grant permission for:</b></Text>
						<Text disabled={decline}><b>Aleutian Pribilof Islands Association, Inc., Behavioral Health</b></Text>
						<Text disabled={decline}><b>1131 E. International Airport Rd., Anchorage, AK 99518</b></Text>
						<Text disabled={decline}><b>Main: 907-276-2700 Fax: 907-222-4279</b></Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text disabled={decline}><b>Select which APIA clinic you grant permission for (check all that apply):</b></Text>
								</Grid>
								<Grid item xs={GRID_XS} sm={GRID_SM}>
									<Tooltip title="Atka Health Clinic">
										<>
											<CheckBox
												text="Atka Health Clinic"
												disabled={decline}
												value={atkaHealthClinic}
												onValueChanged={onToggleAtkaHealthClinic} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS} sm={GRID_SM}>
									<Tooltip title="Anchorage Behavioral Health Clinic">
										<>
											<CheckBox
												text="Anchorage Behavioral Health Clinic"
												disabled={decline}
												value={anchorageBehavioralHealth}
												onValueChanged={onToggleAnchorageBehavioralHealth} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS} sm={GRID_SM}>
									<Tooltip title="Nikolski Health Center">
										<>
											<CheckBox
												text="Nikolski Health Center"
												disabled={decline}
												value={nikolskiHealthCenter}
												onValueChanged={onToggleNikolskiHealthCenter} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS} sm={GRID_SM}>
									<Tooltip title="Saint George Health Center">
										<>
											<CheckBox
												text="Saint George Health Center"
												disabled={decline}
												value={saintGeorgeHealthCenter}
												onValueChanged={onToggleSaintGeorgeHealthCenter} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS} sm={GRID_SM}>
									<Tooltip title="Oonalaska Wellness Center (primary care)">
										<>
											<CheckBox
												text="Oonalaska Wellness Center (primary care)"
												disabled={decline}
												value={oonalaskaPrimaryCare}
												onValueChanged={onToggleOonalaskaPrimaryCare} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS} sm={GRID_SM}>
									<Tooltip title="Oonalaska Wellness Center (behavioral health)">
										<>
											<CheckBox
												text="Oonalaska Wellness Center (behavioral health)"
												disabled={decline}
												value={oonalaskaBehavioralHealth}
												onValueChanged={onToggleOonalaskaBehavioralHealth} />
										</>
									</Tooltip>
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text disabled={decline}><b>To (check all that apply):</b></Text>
								</Grid>
								<Grid item xs={GRID_XS} sm={GRID_SM}>
									<Tooltip title="Release Information to">
										<>
											<CheckBox
												text="Release Information to"
												disabled={decline}
												value={releaseInformationTo}
												onValueChanged={onToggleReleaseInformationTo} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS} sm={GRID_SM}>
									<Tooltip title="Obtain information from">
										<>
											<CheckBox
												text="Obtain information from"
												disabled={decline}
												value={obtainInformationFrom}
												onValueChanged={onToggleObtainInformationFrom} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS} sm={GRID_SM}>
									<Tooltip title="Verbally">
										<>
											<CheckBox
												text="Verbally"
												disabled={decline}
												value={verbally}
												onValueChanged={onToggleVerbally} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS} sm={GRID_SM}>
									<Tooltip title="In writing and/or electronically">
										<>
											<CheckBox
												text="In writing and/or electronically"
												disabled={decline}
												value={inWritingElectronically}
												onValueChanged={onToggleInWritingElectronically} />
										</>
									</Tooltip>
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Name *">
							<>
								<TextBox
									label="Name *"
									value={agencyName}
									onValueChanged={onChangeAgencyName}
									disabled={decline}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-agencyName' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Name is required"
										/>
										<PatternRule
											message="Do not use digits in the Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Agency *">
							<>
								<TextBox
									label="Agency *"
									value={agency}
									onValueChanged={onChangeAgency}
									disabled={decline}
									maxLength={140}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-agency' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Name is required"
										/>
										<PatternRule
											message="Do not use digits in the Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Address *">
							<>
								<TextBox
									label="Address *"
									value={agencyAddress}
									onValueChanged={onChangeAgencyAddress}
									disabled={decline}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-agencyAddress' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Address is required"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="City *">
							<>
								<TextBox
									label="City *"
									value={agencyCity}
									onValueChanged={onChangeAgencyCity}
									disabled={decline}
									maxLength={20}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-agencyCity' }}>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="City is required"
										/>
										<PatternRule
											message="Do not use digits in the City"
											pattern={CITY_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="State *">
							<>
								<SelectBox
									label="State *"
									defaultValue={agencyState}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={onChangeAgencyState}
									disabled={decline}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-agencyState' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="State is required"
										/>
										<RangeRule
											message="State is required"
											min={0} />
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Zip *">
							<>
								<TextBox
									label="Zip *"
									value={agencyZip}
									onValueChanged={onChangeAgencyZip}
									disabled={decline}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={ZIP_MASK}
									maskRules={{ X: /[0-9]/ }}
									inputAttr={{ autocomplete: 'new-agencyZip' }} >
									<Validator validationGroup={sectionName}>
										<CustomRule
											message="This field is required"
											validationCallback={({ value }) => value.length === 5}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Phone *">
							<>
								<TextBox
									label="Phone *"
									value={agencyPhone}
									onValueChanged={onChangeAgencyPhone}
									disabled={decline}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									inputAttr={{ autocomplete: 'new-agencyPhone' }} >
									<Validator validationGroup={sectionName}>
										<CustomRule
											message="This field is required"
											validationCallback={({ value }) => value.length === 10}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text disabled={decline}><b>Regarding the following information (check all that apply):</b></Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Grid container spacing={2}>
										<Grid item xs={GRID_XS}>
											<Tooltip title="Assessment, including diagnosis & treatment recommendations (Sub Use Only, MH Only, Integrated)">
												<>
													<CheckBox
														text="Assessment, including diagnosis & treatment recommendations (Sub Use Only, MH Only, Integrated)"
														value={assessmentRecommendations}
														onValueChanged={onToggleAssessmentRecommendations}
														disabled={decline} />
												</>
											</Tooltip>
										</Grid>
										<Grid item xs={GRID_XS}>
											<Tooltip title="Results of Urinalysis and/or alcohol breathalyzer">
												<>
													<CheckBox
														text="Results of Urinalysis and/or alcohol breathalyzer"
														value={resultsOfUrinalysis}
														onValueChanged={onToggleResultsOfUrinalysis}
														disabled={decline} />
												</>
											</Tooltip>
										</Grid>
										<Grid item xs={GRID_XS}>
											<Tooltip title="Treatment Plan">
												<>
													<CheckBox
														text="Treatment Plan"
														value={treatmentPlan}
														onValueChanged={onToggleTreatmentPlan}
														disabled={decline} />
												</>
											</Tooltip>
										</Grid>
										<Grid item xs={GRID_XS}>
											<Tooltip title="Treatment Status or progress in treatment">
												<>
													<CheckBox
														text="Treatment Status or progress in treatment"
														value={treatmentStatus}
														onValueChanged={onToggleTreatmentStatus}
														disabled={decline} />
												</>
											</Tooltip>
										</Grid>
										<Grid item xs={GRID_XS}>
											<Tooltip title="BH Medication Needs">
												<>
													<CheckBox
														text="BH Medication Needs"
														value={bhMedicationNeeds}
														onValueChanged={onToggleBhMedicationNeeds}
														disabled={decline} />
												</>
											</Tooltip>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Grid container spacing={2}>
										<Grid item xs={GRID_XS}>
											<Tooltip title="Admission Summary">
												<>
													<CheckBox
														text="Admission Summary"
														value={admissionSummary}
														onValueChanged={onToggleAdmissionSummary}
														disabled={decline} />
												</>
											</Tooltip>
										</Grid>
										<Grid item xs={GRID_XS}>
											<Tooltip title="Attendance/Compliance">
												<>
													<CheckBox
														text="Attendance/Compliance"
														value={attendanceCompliance}
														onValueChanged={onToggleAttendanceCompliance}
														disabled={decline} />
												</>
											</Tooltip>
										</Grid>
										<Grid item xs={GRID_XS}>
											<Tooltip title="Psychiatric Evaluations or Psychological Assessments">
												<>
													<CheckBox
														text="Psychiatric Evaluations or Psychological Assessments"
														value={psychiatricEvaluations}
														onValueChanged={onTogglePsychiatricEvaluations}
														disabled={decline} />
												</>
											</Tooltip>
										</Grid>
										<Grid item xs={GRID_XS}>
											<Tooltip title="Discharge Date and Summary">
												<>
													<CheckBox
														text="Discharge Date and Summary"
														value={dischargeDateAndSummary}
														onValueChanged={onToggleDischargeDateAndSummary}
														disabled={decline} />
												</>
											</Tooltip>
										</Grid>
										<Grid item xs={GRID_XS}>
											<Tooltip title="Other">
												<>
													<CheckBox
														text="Other"
														value={regardingOther}
														onValueChanged={onToggleRegardingOther}
														disabled={decline} />
												</>
											</Tooltip>
										</Grid>
									</Grid>
								</Grid>
								{
									regardingOther &&
									<Grid item xs={GRID_XS}>
										<Tooltip title="Please Specify">
											<>
												<TextBox
													label="Please Specify"
													value={regardingOtherText}
													onValueChanged={onChangeRegardingOtherText}
													disabled={decline}
													maxLength={30}
													labelMode="floating"
													stylingMode="outlined"
													inputAttr={{ autocomplete: 'new-regardingOtherText' }} />
											</>
										</Tooltip>
									</Grid>
								}
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text disabled={decline}><b>For the following purposes (check all that apply):</b></Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Referral/Treatment Placement follow-up">
										<>
											<CheckBox
												text="Referral/Treatment Placement follow-up"
												value={referralTreatmentPlacement}
												onValueChanged={onToggleReferralTreatmentPlacement}
												disabled={decline} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Provide referral information">
										<>
											<CheckBox
												text="Provide referral information"
												value={provideReferralInformation}
												onValueChanged={onToggleProvideReferralInformation}
												disabled={decline} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Verify participation in treatment">
										<>
											<CheckBox
												text="Verify participation in treatment"
												value={verifyParticipationInTreatment}
												onValueChanged={onToggleVerifyParticipationInTreatment}
												disabled={decline} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Coordination of services or care">
										<>
											<CheckBox
												text="Coordination of services or care"
												value={coordinationOfServices}
												onValueChanged={onToggleCoordinationOfServices}
												disabled={decline} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Other">
										<>
											<CheckBox
												text="Other"
												value={purposesOther}
												onValueChanged={onTogglePurposesOther}
												disabled={decline} />
										</>
									</Tooltip>
								</Grid>
								{
									purposesOther &&
									<Grid item xs={GRID_XS}>
										<Tooltip title="Please Specify">
											<>
												<TextBox
													label="Please Specify"
													value={purposesOtherText}
													onValueChanged={onChangePurposesOtherText}
													disabled={decline}
													maxLength={30}
													labelMode="floating"
													stylingMode="outlined"
													inputAttr={{ autocomplete: 'new-purposesOtherText' }} />
											</>
										</Tooltip>
									</Grid>
								}
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={decline}>I understand that my alcohol and/or drug treatment records are protected under Federal laws governing the confidentiality of
							substance use disorder records (42 C.F.R. Part 2) and the Health Insurance Portability and Accountability Act of 1996
							(“HIPAA”), and cannot be disclosed without my written consent unless otherwise provided for by the regulations. Treatment
							and benefits will not be contingent on signing this ROI, except to the extent that an ROI is necessary under 42 CFR Part 2 for
							payment purposes. <strong>I also understand that I may revoke this consent orally, pursuant to 42 C.F.R. Part 2, or in writing at
								any time</strong> except to the extent that action has been taken in reliance on it (any information that has already been released cannot
							be recalled). This consent will expire one year from date signed or on (whichever is earlier):</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Printed Name *">
							<>
								<TextBox
									label="Printed Name *"
									value={printedName}
									onValueChanged={onChangePrintedName}
									disabled={decline}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-printedName' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Printed Name is required"
										/>
										<PatternRule
											message="Do not use digits in the Printed Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Signed Date *">
							<>
								<DateBox
									type="date"
									label="Signed Date *"
									value={dateFormatFromApi(signedDate)}
									onValueChanged={onChangeSignedDate}
									disabled={decline}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Signed Date is required"
										/>
										<RangeRule
											message="Date cannot be earlier or later than that year"
											min={MIN_CURRENT_DATE}
											max={MAX_CURRENT_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Expired Date *">
							<>
								<DateBox
									type="date"
									label="Expired Date *"
									value={dateFormatFromApi(expiredDate)}
									onValueChanged={onChangeExpiredDate}
									disabled={decline}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Expired Date is required"
										/>
										<RangeRule
											message="Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Signature
							error={error}
							disabled={decline}>
							<legend>Signature *</legend>
							<SignatureCanvas
								ref={onInitSignature}
								onEnd={onChangeSignature}
								clearOnResize={false}
								canvasProps={{
									id: 'responsive-canvas'
								}}
							/>
						</Signature>
					</Grid>
					<Grid item xs={GRID_XS} >
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								disabled={decline}
								validationGroup={sectionName}
								onClick={onSaveConsentForRelease} />
							<Button
								text="Clear Signature"
								type="default"
								stylingMode="outlined"
								disabled={decline}
								onClick={onClearSignature} />
						</Buttons>
					</Grid>
				</Grid>
			}
		</Wrapper >
	)
}