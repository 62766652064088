import { Wrapper, Item, Label, TextBox, DateBox, SelectBox, Buttons } from '../../BasicInformation.style';
//import { CURRENT_DATE, MAX_WIDTH, MIN_DATE, MAX_DOB, TEXT_DOB, GUARDIAN_INFO, ZIP_MASK, PHONE_MASK, GRID_XS } from '../../../../../../utils/constants';
import { MAX_WIDTH, GUARDIAN_INFO, ZIP_MASK, PHONE_MASK, GRID_XS } from '../../../../../../utils/constants';
import { NAME_RULE } from '../../../../../../utils/rules';
import { dateFormatFromApi } from '../../../../../../utils/formats';
import { onKeyDownHandle } from '../../../../../../utils/helper';
import { useResizeDetector } from 'react-resize-detector';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	EmailRule,
	PatternRule,
	//RangeRule,
} from 'devextreme-react/validator';

const setSizeByWidth = (width) => {
	switch (true) {
		case (width <= 600): return 12;
		case (width > 600 && width <= 900): return 6;
		case (width > 900 && width <= 1200): return 4;
		case (width > 1200 && width <= 1500): return 3;
		case (width > 1500): return 2;
		default: return 12;
	};
};

export const GuardianInformation = ({
	showButton,

	states,

	getEntityPatientProfileGuardianInfo,
	onChangeFunc,

	onCancelGuardianInfo,
	onSaveGuardianInfo,
}) => {
	const { width, ref } = useResizeDetector();
	const [size, setSize] = useState(12);

	useEffect(() => {
		setSize(setSizeByWidth(width));
	}, [width]);

	return (
		<Wrapper id={GUARDIAN_INFO} ref={ref} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityPatientProfileGuardianInfo('loading')}
				position={{ of: `#${GUARDIAN_INFO}` }} />
			{
				!getEntityPatientProfileGuardianInfo('loading') &&
				<Grid container spacing={2}>
					<Item item xs={size}>
						<Tooltip title="First Name">
							<span>
								<Label>First Name:</Label>
								<TextBox
									label="First Name"
									value={getEntityPatientProfileGuardianInfo('firstName')}
									onValueChanged={(e) => onChangeFunc('FirstName', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-firstName' }}
								>
									<Validator validationGroup={GUARDIAN_INFO}>
										<PatternRule
											message="Do not use digits in the First Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Middle Name">
							<span>
								<Label>Middle Name:</Label>
								<TextBox
									label="Middle Name"
									value={getEntityPatientProfileGuardianInfo('middleName')}
									onValueChanged={(e) => onChangeFunc('MiddleName', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-middleName' }}
								>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Last Name">
							<span>
								<Label>Last Name:</Label>
								<TextBox
									label="Last Name"
									value={getEntityPatientProfileGuardianInfo('lastName')}
									onValueChanged={(e) => onChangeFunc('LastName', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-lastName' }}
								>
									<Validator validationGroup={GUARDIAN_INFO}>
										<PatternRule
											message="Do not use digits in the Last Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Date of Birth">
							<span>
								<Label>Date of Birth:</Label>
								<DateBox
									type="date"
									label="Date of Birth"
									value={dateFormatFromApi(getEntityPatientProfileGuardianInfo('dateOfBirth'))}
									onValueChanged={(e) => onChangeFunc('DateOfBirth', e)}
									useMaskBehavior={true}
									showDropDownButton={false}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="hidden"
									stylingMode="underlined"
								>
									{/* <Validator validationGroup={GUARDIAN_INFO}>
										<RangeRule
											message="Date of Birth cannot be younger than the current date and earlier than 1900"
											min={MIN_DATE}
											max={CURRENT_DATE} />
										<RangeRule
											message={TEXT_DOB}
											max={MAX_DOB} />
									</Validator> */}
								</DateBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Address">
							<span>
								<Label>Address:</Label>
								<TextBox
									label="Address"
									value={getEntityPatientProfileGuardianInfo('address')}
									onValueChanged={(e) => onChangeFunc('Address', e, true)}
									maxLength={150}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-address' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="State">
							<span>
								<Label>State:</Label>
								<SelectBox
									label="State"
									defaultValue={getEntityPatientProfileGuardianInfo('state')}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={(e) => onChangeFunc('State', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-state' }}
								/>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="City">
							<span>
								<Label>City:</Label>
								<TextBox
									label="City"
									value={getEntityPatientProfileGuardianInfo('city')}
									onValueChanged={(e) => onChangeFunc('City', e, true)}
									maxLength={20}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-city' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Zip">
							<span>
								<Label>Zip:</Label>
								<TextBox
									label="Zip"
									value={getEntityPatientProfileGuardianInfo('zip')}
									onValueChanged={(e) => onChangeFunc('Zip', e)}
									labelMode="hidden"
									stylingMode="underlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={ZIP_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="Zip must have at least 5 symbols"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-zip' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Phone">
							<span>
								<Label>Phone:</Label>
								<TextBox
									label="Phone"
									value={getEntityPatientProfileGuardianInfo('phone')}
									onValueChanged={(e) => onChangeFunc('Phone', e)}
									labelMode="hidden"
									stylingMode="underlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Email *">
							<span>
								<Label>Email:</Label>
								<TextBox
									label="Email *"
									value={getEntityPatientProfileGuardianInfo('email')}
									onValueChanged={(e) => onChangeFunc('Email', e, true)}
									maxLength={64}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-email' }}>
									<Validator validationGroup={GUARDIAN_INFO}>
										<EmailRule
											message="Email is invalid"
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Employer">
							<span>
								<Label>Employer:</Label>
								<TextBox
									label="Employer"
									value={getEntityPatientProfileGuardianInfo('employer')}
									onValueChanged={(e) => onChangeFunc('Employer', e, true)}
									maxLength={120}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-employer' }}
								>
									<Validator validationGroup={GUARDIAN_INFO}>
										<PatternRule
											message="Do not use digits in the Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={GUARDIAN_INFO}
							onClick={onSaveGuardianInfo} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelGuardianInfo} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper>
	);
};