import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { selectLoading, selectBackCard, selectBackId, selectDate, selectEmail, selectFrontCard, selectFrontId, selectFirstName, selectMiddleName, selectLastName, selectCib, selectTribalCard, selectTribalEnrollment } from '../../../store/forms/idAndInsuranceCard/selectors';
import { setLoading, setBackCard, setBackId, setDate, setEmail, setFrontCard, setFrontId, setFirstName, setMiddleName, setLastName, setCib, setTribalCard, setTribalEnrollment } from '../../../store/forms/idAndInsuranceCard/slice';
import { getIdAndInsuranceCard, saveIdAndInsuranceCard, getIdAndInsuranceCardByID, saveIdAndInsuranceCardByID } from '../../../store/forms/idAndInsuranceCard/middlewares';
import { completeIdAndInsuranceCard } from '../../../store/forms/newPatientRegistrationPacket/slice';
import { setNewActiveStep } from '../../../store/forms/newPatientRegistrationPacket/middlewares';

import { IDAndInsuranceCard } from './IDAndInsuranceCard';
import { useDispatch, useSelector } from 'react-redux';
import { validateGroup, downloadFile } from '../../../utils/helper';
import { BASE_URL, USER_PATH } from '../../../utils/constants';
import { get } from '../../../core/rest';
import { useEffect } from 'react';
import { showToast } from '../../Main/MainContainer';

export const IDAndInsuranceCardContainer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const firstName = useSelector(selectFirstName);
    const middleName = useSelector(selectMiddleName);
    const lastName = useSelector(selectLastName);
    const date = useSelector(selectDate);
    const email = useSelector(selectEmail);
    const frontId = useSelector(selectFrontId);
    const backId = useSelector(selectBackId);
    const frontCard = useSelector(selectFrontCard);
    const backCard = useSelector(selectBackCard);
    const cib = useSelector(selectCib);
    const tribalCard = useSelector(selectTribalCard);
    const tribalEnrollment = useSelector(selectTribalEnrollment);

    useEffect(() => {
        dispatch(setLoading(true));
        id ? dispatch(getIdAndInsuranceCardByID(id)) : dispatch(getIdAndInsuranceCard());
    }, [dispatch, id]);

    const onChangeFirstName = (event) => {
        dispatch(setFirstName(event.value.trim()));
    };

    const onChangeMiddleName = (event) => {
        dispatch(setMiddleName(event.value.trim()));
    };

    const onChangeLastName = (event) => {
        dispatch(setLastName(event.value.trim()));
    };

    const onChangeDate = (event) => {
        dispatch(setDate(event.value));
    };

    const onChangeEmail = (event) => {
        dispatch(setEmail(event.value.trim()));
    };

    const onSelectFrontId = async () => {
        const result = await get(BASE_URL + "/FileUploader/findLast/0");

        const data = JSON.stringify({
            id: result.id,
            fileName: result.fileName,
            size: result.size,
        })

        dispatch(setFrontId(data));
    };

    const onSelectBackId = async () => {
        const result = await get(BASE_URL + "/FileUploader/findLast/1");

        const data = JSON.stringify({
            id: result.id,
            fileName: result.fileName,
            size: result.size,
        })

        dispatch(setBackId(data));
    };

    const onSelectFrontCard = async () => {
        const result = await get(BASE_URL + "/FileUploader/findLast/2");

        const data = JSON.stringify({
            id: result.id,
            fileName: result.fileName,
            size: result.size,
        })

        dispatch(setFrontCard(data));
    };

    const onSelectBackCard = async () => {
        const result = await get(BASE_URL + "/FileUploader/findLast/3");

        const data = JSON.stringify({
            id: result.id,
            fileName: result.fileName,
            size: result.size,
        })

        dispatch(setBackCard(data));
    };

    const onSelectCib = async () => {
        const result = await get(BASE_URL + "/FileUploader/findLast/4");

        const data = JSON.stringify({
            id: result.id,
            fileName: result.fileName,
            size: result.size,
        })

        dispatch(setCib(data));
    };

    const onSelectTribalCard = async () => {
        const result = await get(BASE_URL + "/FileUploader/findLast/5");

        const data = JSON.stringify({
            id: result.id,
            fileName: result.fileName,
            size: result.size,
        });

        dispatch(setTribalCard(data));
    };

    const onSelectTribalEnrollment = async () => {
        const result = await get(BASE_URL + "/FileUploader/findLast/6");

        const data = JSON.stringify({
            id: result.id,
            fileName: result.fileName,
            size: result.size,
        });

        dispatch(setTribalEnrollment(data));
    };

    const onClickAttachedFile = (ID, fileName) => {
        get(BASE_URL + `/FileUploader/download/${ID}`, {
            Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('AUTH_TOKEN_DATA')).accessToken,
            responseType: 'blob',
        })
            .then((blob) => downloadFile(blob, fileName))
            .catch(() => showToast('error', `Unable to download ${fileName}`));
    };

    const onSaveIDAndInsuranceCard = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });

            dispatch(id ? saveIdAndInsuranceCardByID(id) : saveIdAndInsuranceCard());
            dispatch(completeIdAndInsuranceCard(true));
            dispatch(setNewActiveStep());
        }
    };

    const onClickBack = () => {
        const path = location.state?.path ? location.state.path : window.localStorage.getItem(USER_PATH);
        navigate(`/main/users/${path}/profile/${id}`, { state: { indexTab: 2 } });
    };

    return (
        <IDAndInsuranceCard
            loading={loading}

            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            date={date}
            email={email}
            frontId={frontId ? JSON.parse(frontId) : frontId}
            backId={backId ? JSON.parse(backId) : backId}
            frontCard={frontCard ? JSON.parse(frontCard) : frontCard}
            backCard={backCard ? JSON.parse(backCard) : backCard}
            cib={cib ? JSON.parse(cib) : cib}
            tribalCard={tribalCard ? JSON.parse(tribalCard) : tribalCard}
            tribalEnrollment={tribalEnrollment ? JSON.parse(tribalEnrollment) : tribalEnrollment}

            onChangeFirstName={onChangeFirstName}
            onChangeMiddleName={onChangeMiddleName}
            onChangeLastName={onChangeLastName}
            onChangeDate={onChangeDate}
            onChangeEmail={onChangeEmail}
            onSelectFrontId={onSelectFrontId}
            onSelectBackId={onSelectBackId}
            onSelectFrontCard={onSelectFrontCard}
            onSelectBackCard={onSelectBackCard}
            onSelectCib={onSelectCib}
            onSelectTribalCard={onSelectTribalCard}
            onSelectTribalEnrollment={onSelectTribalEnrollment}

            onClickAttachedFile={onClickAttachedFile}
            onSaveIDAndInsuranceCard={onSaveIDAndInsuranceCard}
            onClickBack={onClickBack}
        />
    );
}