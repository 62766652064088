import { styled } from '@mui/material/styles';
import TextArea from 'devextreme-react/text-area';
import { Grid } from '@mui/material';

export const Wrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    margin: '30px 40px 0',
    '@media (max-width: 768px)': {
        margin: '10px 20px 0',
        paddingBottom: '40px',
    },
}));

export const TitleWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '20px',
    '@media (max-width: 425px)': {
        marginBottom: 'unset',
    },
}));

export const Title = styled('h1')(() => ({
    color: 'var(--dark-blue-color)',
    fontFamily: 'Montserrat',
    userSelect: 'none',
    lineHeight: '44px',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: '700',
    margin: '0px',
    '@media (max-width: 425px)': {
        fontSize: '16px',
        lineHeight: '20px',
    },
}));

export const SubTitle = styled('h2', {
    shouldForwardProp: (prop) => prop !== 'disabled'
})(({ disabled }) => ({
    color: 'var(--dark-blue-color)',
    fontFamily: 'Montserrat',
    lineHeight: '22px',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '600',
    margin: '0px',
    ...(disabled && {
        opacity: '.38',
        userSelect: 'none',
    }),
    '@media (max-width: 425px)': {
        fontSize: '16px',
        lineHeight: '20px',
    },
}));

export const Text = styled('p', {
    shouldForwardProp: (prop) => prop !== 'disabled'
})(({ disabled }) => ({
    color: 'var(--black-blue-color)',
    fontFamily: 'Montserrat',
    lineHeight: '22px',
    fontWeight: '500',
    fontSize: '14px',
    margin: 'unset',
    ...(disabled && {
        opacity: '.38',
        userSelect: 'none',
    }),
    '@media (max-width: 425px)': {
        fontSize: '14px',
        lineHeight: '20px',
    },
}));

export const Fieldset = styled('fieldset', {
    shouldForwardProp: (prop) => prop !== 'disabled'
})(({ disabled }) => ({
    backgroundColor: 'var(--background-color)',
    borderColor: 'var(--light-grey-color)',
    borderStyle: 'solid',
    borderRadius: '4px',
    ...(disabled && {
        opacity: '.38',
        userSelect: 'none',
    }),
}));

export const UnorderedList = styled('ul', {
    shouldForwardProp: (prop) => prop !== 'disabled'
})(({ disabled }) => ({
    color: 'var(--black-blue-color)',
    fontFamily: 'Montserrat',
    lineHeight: '22px',
    fontWeight: '500',
    fontSize: '14px',
    margin: '0px',
    ...(disabled && {
        opacity: '.38',
        userSelect: 'none',
    }),
    '@media (max-width: 425px)': {
        fontSize: '14px',
        lineHeight: '20px',
    },
}));

export const UnorderedListLowerRoman = styled('ul', {
    shouldForwardProp: (prop) => prop !== 'disabled'
})(({ disabled }) => ({
    color: 'var(--black-blue-color)',
    fontFamily: 'Montserrat',
    lineHeight: '22px',
    fontWeight: '500',
    fontSize: '14px',
    margin: '0px',
    listStyleType: 'lower-roman',
    ...(disabled && {
        opacity: '.38',
        userSelect: 'none',
    }),
    '@media (max-width: 425px)': {
        fontSize: '14px',
        lineHeight: '20px',
    },
}));

export const OrderedList = styled('ol', {
    shouldForwardProp: (prop) => prop !== 'disabled'
})(({ disabled }) => ({
    color: 'var(--black-blue-color)',
    fontFamily: 'Montserrat',
    lineHeight: '22px',
    fontWeight: '500',
    fontSize: '14px',
    margin: '0px',
    ...(disabled && {
        opacity: '.38',
        userSelect: 'none',
    }),
    '@media (max-width: 425px)': {
        fontSize: '14px',
        lineHeight: '20px',
    },
}));

export const Item = styled('li', {
    shouldForwardProp: (prop) => prop !== 'disabled'
})(({ disabled }) => ({
    color: 'var(--black-blue-color)',
    fontFamily: 'Montserrat',
    lineHeight: '22px',
    fontWeight: '500',
    fontSize: '14px',
    padding: '0px',
    ...(disabled && {
        opacity: '.38',
        userSelect: 'none',
    }),
    '@media (max-width: 425px)': {
        fontSize: '14px',
        lineHeight: '20px',
    },
}));

export const Link = styled('a')(() => ({
    color: 'var(--light-blue-color)',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    margin: 'unset',
    cursor: 'pointer',
    textDecoration: 'none',
    '@media (max-width: 425px)': {
        fontSize: '14px',
        lineHeight: '20px',
    },
}));

export const CheckboxGrid = styled(Grid)(() => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
}));

export const Buttons = styled(Grid)(() => ({
    marginBottom: '30px',
    justifyContent: 'flex-end',
    display: 'flex',
    gap: '10px',
}));

export const TextAreaItem = styled(TextArea)(() => ({
    '& .dx-texteditor-input': {
        padding: '0px !important',
    },
    '@media (min-width: 426px)': {
        '& .dx-texteditor-input': {
            height: '20px',
            overflow: 'hidden',
        },
    },
}));

export const TextAreaField = styled(TextArea)(() => ({
    '@media (min-width: 426px)': {
        '& .dx-texteditor-input': {
            height: '48px',
            overflow: 'hidden',
            padding: '13px 16px 14px',
        },
    },
}));