//import { CURRENT_DATE, MAX_DATE, MAX_WIDTH, MIN_DATE, MAX_DOB, TEXT_DOB, INSURANCE_INFO, PHONE_MASK, ZIP_MASK, GRID_XS, GRID_SM, GRID_MD } from '../../../../../utils/constants';
import { MAX_DATE, MAX_WIDTH, MIN_DATE, INSURANCE_INFO, PHONE_MASK, ZIP_MASK, GRID_XS, GRID_SM, GRID_MD } from '../../../../../utils/constants';
import { NAME_RULE } from '../../../../../utils/rules';
import { onKeyDownHandle } from '../../../../../utils/helper';
import { Wrapper, Text, OrderedList, Item, CheckboxGrid } from '../../../../../components/Main/Main.style';
import { dateFormatFromApi } from '../../../../../utils/formats';
import { Grid } from '@mui/material';
import DataGrid, { Column, Editing, Toolbar, Item as ItemToolbar, Scrolling } from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import CheckBox from 'devextreme-react/check-box';
import DateBox from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	PatternRule,
	RangeRule,
} from 'devextreme-react/validator';

export const InsuranceInfo = ({
	loading,

	totalHouseholdIncomes,
	denaliKidStatuses,
	medicaidStatuses,
	medicareStatuses,
	states,

	dontHaveInsurance,
	familyMembers,
	totalHouseholdIncome,
	medicareStatus,
	medicareEffDate,
	medicareExpDate,
	medicareNumber,
	medicaidStatus,
	medicaidEffDate,
	medicaidExpDate,
	medicaidNumber,
	denaliKidStatus,
	denaliKidEffDate,
	denaliKidExpDate,
	denaliKidNumber,
	privateInsurance,
	companyInsurance,
	policyHolder,
	policyHolderNumber,
	policyHolderGroupNumber,
	otherInsurance,
	otherInsuranceNumber,
	otherInsuranceGroupNumber,
	veteran,
	benefitsVA,
	serviceBranch,
	serviceEntryDate,
	serviceExitDate,
	vietnamService,
	doNotOtherInsurance,
	selfPay,
	relationship,
	DOB,
	phone,
	address,
	city,
	state,
	zip,
	effectiveDate,

	onToggleDontHaveInsurance,
	onRowInsertingFamilyMembers,
	onRowRemovingFamilyMembers,
	onRowUpdatingFamilyMembers,
	onChangeIncome,
	onChangeMedicare,
	onChangeMedicareEffDate,
	onChangeMedicareExpDate,
	onChangeMedicareNumber,
	onChangeMedicaid,
	onChangeMedicaidEffDate,
	onChangeMedicaidExpDate,
	onChangeMedicaidNumber,
	onChangeDenaliKid,
	onChangeDenaliKidEffDate,
	onChangeDenaliKidExpDate,
	onChangeDenaliKidNumber,
	onTogglePrivateInsurance,
	onChangeCompanyInsurance,
	onChangePolicyHolder,
	onChangePolicyHolderNumber,
	onChangePolicyHolderGroupNumber,
	onChangeOtherInsurance,
	onChangeOtherInsuranceNumber,
	onChangeOtherInsuranceGroupNumber,
	onToggleVeteran,
	onToggleBenefitsVA,
	onChangeServiceBranch,
	onChangeServiceEntryDate,
	onChangeServiceExitDate,
	onToggleVietnamService,
	onToggleDoNotOtherInsurance,
	onChangeSelfPay,
	onChangeRelationship,
	onChangeDOB,
	onChangePhone,
	onChangeAddress,
	onChangeCity,
	onChangeState,
	onChangeZip,
	onChangeEffectiveDate,

	onSaveInsuranceInfo,
	onSaveAndContinueInsuranceInfo,
}) => {

	const checkboxStatusInsurance = { display: dontHaveInsurance ? "none" : "block" };

	return (
		< Wrapper id={INSURANCE_INFO} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${INSURANCE_INFO}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<CheckboxGrid item xs={GRID_XS}>
						<Tooltip title="I don't have insurance">
							<>
								<CheckBox
									text="I don't have insurance"
									value={dontHaveInsurance}
									onValueChanged={onToggleDontHaveInsurance} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<Grid item xs={GRID_XS}>
						<Text style={{ paddingBottom: '8px' }}>
							Please list ALL members (people) in the household (spouse, child, foster child, grandparent, aunt, uncle, etc.).
						</Text>
						<OrderedList>
							<Item>To add a person, click on the "Add a Row" button and start typing.</Item>
							<Item>To save your entry, click on the floppy disk icon at the end of a row or press "Enter".</Item>
							<Item>Once saved, you can come back and edit/delete an entry by clicking on the corresponding icon at the end of a row.</Item>
							<Item>Repeat steps 1 and 2 to add more people.</Item>
						</OrderedList>
					</Grid>
					<Grid item xs={GRID_XS}>
						<DataGrid
							id="grid"
							keyExpr="id"
							showBorders={true}
							dataSource={familyMembers}
							onRowInserting={onRowInsertingFamilyMembers}
							onRowRemoving={onRowRemovingFamilyMembers}
							onRowUpdating={onRowUpdatingFamilyMembers}>
							<Toolbar>
								<ItemToolbar location="before" name='addRowButton' showText />
							</Toolbar>
							<Editing
								mode="row"
								allowAdding={true}
								allowUpdating={true}
								allowDeleting={true}
								confirmDelete={false} />
							<Column
								dataField="name"
								caption="Full Name"
								alignment="left"
								minWidth={130}>
								<RequiredRule />
							</Column>
							<Column
								dataField="relationship"
								caption="Relationship"
								alignment="left"
								minWidth={150} />
							<Column
								dataField="age"
								caption="Age"
								dataType="number"
								alignment="left"
								minWidth={80}
								editorOptions={{
									min: 0,
									max: 120,
									format: 'fixedPoint'
								}} />
							<Scrolling showScrollbar='always' />
						</DataGrid>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>Total annual household income information will help us screen if you are eligible to apply for the APIA Sliding Fee/Discount Schedule.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Total Annual Household Income Estimate">
							<>
								<SelectBox
									label="Total Annual Household Income Estimate"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={
										(totalHouseholdIncome === -1) ? null : totalHouseholdIncome
									}
									dataSource={totalHouseholdIncomes}
									onValueChanged={onChangeIncome}
									validationMessagePosition="bottom"
									className="label__medium"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-totalHouseholdIncome' }}>
									<Validator validationGroup={INSURANCE_INFO}>
										<RequiredRule
											message="Total Annual Household Income Estimate is required"
										/>
										{/* <PatternRule
											message="Do not use digits in the Total Annual Household Income Estimate"
											pattern={NAME_RULE}
										/> */}
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={3} style={checkboxStatusInsurance}>
						<Tooltip title="Medicare">
							<>
								<SelectBox
									label="Medicare"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={medicareStatus}
									dataSource={medicareStatuses}
									onValueChanged={onChangeMedicare}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-medicareStatus' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={3} style={checkboxStatusInsurance}>
						<Tooltip title="Effective date">
							<>
								<DateBox
									type="date"
									label="Effective date"
									value={dateFormatFromApi(medicareEffDate)}
									onValueChanged={onChangeMedicareEffDate}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={3} style={checkboxStatusInsurance}>
						<Tooltip title="Exp Date">
							<>
								<DateBox
									type="date"
									label="Exp Date"
									value={dateFormatFromApi(medicareExpDate)}
									onValueChanged={onChangeMedicareExpDate}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={3} style={checkboxStatusInsurance}>
						<Tooltip title="#">
							<>
								<TextBox
									label="#"
									value={medicareNumber}
									onValueChanged={onChangeMedicareNumber}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-medicareNumber' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={3} style={checkboxStatusInsurance}>
						<Tooltip title="Medicaid">
							<>
								<SelectBox
									label="Medicaid"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={medicaidStatus}
									dataSource={medicaidStatuses}
									onValueChanged={onChangeMedicaid}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-medicaidStatus' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={3} style={checkboxStatusInsurance}>
						<Tooltip title="Effective date">
							<>
								<DateBox
									type="date"
									label="Effective date"
									defaultValue={dateFormatFromApi(medicaidEffDate)}
									onValueChanged={onChangeMedicaidEffDate}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={3} style={checkboxStatusInsurance}>
						<Tooltip title="Exp Date">
							<>
								<DateBox
									type="date"
									label="Exp Date"
									value={dateFormatFromApi(medicaidExpDate)}
									onValueChanged={onChangeMedicaidExpDate}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={3} style={checkboxStatusInsurance}>
						<Tooltip title="#">
							<>
								<TextBox
									label="#"
									value={medicaidNumber}
									onValueChanged={onChangeMedicaidNumber}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-medicaidNumber' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={3} style={checkboxStatusInsurance}>
						<Tooltip title="Denali Kid">
							<>
								<SelectBox
									label="Denali Kid"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={denaliKidStatus}
									dataSource={denaliKidStatuses}
									onValueChanged={onChangeDenaliKid}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-denaliKidStatus' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={3} style={checkboxStatusInsurance}>
						<Tooltip title="Effective date">
							<>
								<DateBox
									type="date"
									label="Effective date"
									value={dateFormatFromApi(denaliKidEffDate)}
									onValueChanged={onChangeDenaliKidEffDate}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={3} style={checkboxStatusInsurance}>
						<Tooltip title="Exp Date">
							<>
								<DateBox
									type="date"
									label="Exp Date"
									value={dateFormatFromApi(denaliKidExpDate)}
									onValueChanged={onChangeDenaliKidExpDate}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={3} style={checkboxStatusInsurance}>
						<Tooltip title="#">
							<>
								<TextBox
									label="#"
									value={denaliKidNumber}
									onValueChanged={onChangeDenaliKidNumber}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-denaliKidNumber' }} />
							</>
						</Tooltip>
					</Grid>
					<CheckboxGrid item xs={GRID_XS} style={checkboxStatusInsurance}>
						<Tooltip title="Private Insurance">
							<>
								<CheckBox
									text="Private Insurance"
									value={privateInsurance}
									onValueChanged={onTogglePrivateInsurance} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<Grid item xs={GRID_XS} style={checkboxStatusInsurance}>
						<Tooltip title="Insurance Company">
							<>
								<TextBox
									label="Insurance Company"
									value={companyInsurance}
									onValueChanged={onChangeCompanyInsurance}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-companyInsurance' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Policy Holder">
							<>
								<TextBox
									label="Policy Holder"
									value={policyHolder}
									onValueChanged={onChangePolicyHolder}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-policyHolder' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Policy #">
							<>
								<TextBox
									label="Policy #"
									value={policyHolderNumber}
									onValueChanged={onChangePolicyHolderNumber}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-policyHolderNumber' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Group #">
							<>
								<TextBox
									label="Group #"
									value={policyHolderGroupNumber}
									onValueChanged={onChangePolicyHolderGroupNumber}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-policyHolderGroupNumber' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Other Insurance">
							<>
								<TextBox
									label="Other Insurance"
									value={otherInsurance}
									onValueChanged={onChangeOtherInsurance}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-otherInsurance' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Policy #">
							<>
								<TextBox
									label="Policy #"
									value={otherInsuranceNumber}
									onValueChanged={onChangeOtherInsuranceNumber}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-otherInsuranceNumber' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Group #">
							<>
								<TextBox
									label="Group #"
									value={otherInsuranceGroupNumber}
									onValueChanged={onChangeOtherInsuranceGroupNumber}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-otherInsuranceGroupNumber' }} />
							</>
						</Tooltip>
					</Grid>
					<CheckboxGrid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Veteran">
							<>
								<CheckBox
									text="Veteran"
									value={veteran}
									onValueChanged={onToggleVeteran} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<CheckboxGrid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="VA Benefits">
							<>
								<CheckBox
									text="VA Benefits"
									value={benefitsVA}
									onValueChanged={onToggleBenefitsVA} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<CheckboxGrid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Vietnam Service Indicated?">
							<>
								<CheckBox
									text="Vietnam Service Indicated?"
									value={vietnamService}
									onValueChanged={onToggleVietnamService} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<Grid item xs={GRID_XS} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Service Branch">
							<>
								<TextBox
									label="Service Branch"
									value={serviceBranch}
									onValueChanged={onChangeServiceBranch}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-serviceBranch' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Service Entry Date">
							<>
								<DateBox
									type="date"
									label="Service Entry Date"
									value={dateFormatFromApi(serviceEntryDate)}
									onValueChanged={onChangeServiceEntryDate}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Service Exit Date">
							<>
								<DateBox
									type="date"
									label="Service Exit Date"
									value={dateFormatFromApi(serviceExitDate)}
									onValueChanged={onChangeServiceExitDate}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<CheckboxGrid item xs={GRID_XS} style={checkboxStatusInsurance}>
						<Tooltip title="I DO NOT have any other insurance coverage from any other source other than the above mentioned">
							<>
								<CheckBox
									text="I DO NOT have any other insurance coverage from any other source other than the above mentioned"
									value={doNotOtherInsurance}
									onValueChanged={onToggleDoNotOtherInsurance} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Self Pay/Guarantor">
							<>
								<TextBox
									label="Self Pay/Guarantor"
									value={selfPay}
									onValueChanged={onChangeSelfPay}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-selfPay' }}
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<PatternRule
											message="Do not use digits in the Self Pay/Guarantor"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Relationship">
							<>
								<TextBox
									label="Relationship"
									value={relationship}
									onValueChanged={onChangeRelationship}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-relationship' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Date of Birth">
							<>
								<DateBox
									type="date"
									label="Date of Birth"
									value={dateFormatFromApi(DOB)}
									onValueChanged={onChangeDOB}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									{/* <Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Date cannot be younger than the current date and earlier than 1900"
											min={MIN_DATE}
											max={CURRENT_DATE} />
										<RangeRule
											message={TEXT_DOB}
											max={MAX_DOB} />
									</Validator> */}
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Phone">
							<>
								<TextBox
									label="Phone"
									value={phone}
									onValueChanged={onChangePhone}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Address">
							<>
								<TextBox
									label="Address"
									value={address}
									onValueChanged={onChangeAddress}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-address' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="City">
							<>
								<TextBox
									label="City"
									value={city}
									onValueChanged={onChangeCity}
									maxLength={20}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-city' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="State">
							<>
								<SelectBox
									label="State"
									defaultValue={state}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={onChangeState}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-state' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Zip">
							<>
								<TextBox
									label="Zip"
									value={zip}
									onValueChanged={onChangeZip}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={ZIP_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="Zip must have at least 5 symbols"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-zip' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD} style={checkboxStatusInsurance}>
						<Tooltip title="Effective Date">
							<>
								<DateBox
									type="date"
									label="Effective Date"
									value={dateFormatFromApi(effectiveDate)}
									onValueChanged={onChangeEffectiveDate}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right', gap: '10px', display: 'flex', justifyContent: 'flex-end' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="outlined"
							validationGroup={INSURANCE_INFO}
							onClick={onSaveInsuranceInfo} />
						<Button
							text="Save and continue"
							type="default"
							stylingMode="contained"
							validationGroup={INSURANCE_INFO}
							onClick={onSaveAndContinueInsuranceInfo} />
					</Grid>
				</Grid>
			}
		</Wrapper >
	);
};