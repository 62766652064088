import { Dialog, DialogTitle, DialogContent, DialogContentRow, DialogActions } from '../../../components/Dialog/Dialog.style';
//import { CURRENT_DATE, MIN_DATE, MAX_DOB, TEXT_DOB, PHONE_MASK } from '../../../utils/constants';
import { PHONE_MASK } from '../../../utils/constants';
import { dateFormatFromApi } from '../../../utils/formats';
//import { NAME_RULE, PHONE_RULE } from '../../../utils/rules';
import { NAME_RULE } from '../../../utils/rules';
import { onKeyDownHandle } from '../../../utils/helper';

import TextBox from 'devextreme-react/text-box';
import DateBox from 'devextreme-react/date-box';
import Button from 'devextreme-react/button';

import {
	Validator,
	RequiredRule,
	//RangeRule,
	EmailRule,
	PatternRule
} from 'devextreme-react/validator';

const GenerationInvites = ({
	open,

	name,
	email,
	phone,
	dob,
	inviteKey,

	onChangeName,
	onChangeEmail,
	onChangePhone,
	onChangeDOB,

	onClickClose,
	onClickCreateLink,
	onClickSendEmail,
}) => {
	const sectionName = "denerationInvites";
	return (
		<Dialog open={open} onClose={onClickClose} fullWidth maxWidth="sm">
			<DialogTitle>Invite</DialogTitle>
			<DialogContent>
				<DialogContentRow style={{ width: '100%' }} title="Name *">
					<TextBox
						label="Name *"
						value={name}
						disabled={!!inviteKey}
						onValueChanged={onChangeName}
						labelMode="floating"
						stylingMode="outlined"
						inputAttr={{ autocomplete: 'new-name' }}
					>
						<Validator validationGroup={sectionName}>
							<RequiredRule
								message="The Name field is required"
							/>
							<PatternRule
								message="Do not use digits in the Name"
								pattern={NAME_RULE}
							/>
						</Validator>
					</TextBox>
				</DialogContentRow>
				<DialogContentRow style={{ width: '100%' }} title="Email *">
					<TextBox
						label="Email *"
						value={email}
						disabled={!!inviteKey}
						onValueChanged={onChangeEmail}
						labelMode="floating"
						stylingMode="outlined"
						inputAttr={{ autocomplete: 'new-email' }}
					>
						<Validator validationGroup={sectionName}>
							<RequiredRule
								message="The Email field is required"
							/>
							<EmailRule
								message="Please enter a valid email address"
							/>
						</Validator>
					</TextBox>
				</DialogContentRow>
				<DialogContentRow style={{ width: '100%' }} title="Phone *">
					<TextBox
						label="Phone *"
						value={phone}
						disabled={!!inviteKey}
						onValueChanged={onChangePhone}
						labelMode="floating"
						stylingMode="outlined"
						onKeyDown={onKeyDownHandle}
						maskChar="."
						mask={PHONE_MASK}
						showMaskMode="onFocus"
						maskRules={{ X: /[0-9]/ }}
						maskInvalidMessage="Please enter a valid phone number"
						validationMessagePosition="bottom"
						inputAttr={{ autocomplete: 'new-phone' }}
					>
						<Validator validationGroup={sectionName}>
							<RequiredRule
								message="The Phone field is required"
							/>
						</Validator>
					</TextBox>
				</DialogContentRow>
				<DialogContentRow style={{ width: '100%' }} title="Date of Birth *">
					<DateBox
						type="date"
						label="Date of Birth *"
						value={dateFormatFromApi(dob)}
						disabled={!!inviteKey}
						onValueChanged={onChangeDOB}
						useMaskBehavior={true}
						displayFormat="MM/dd/yyyy"
						invalidDateMessage="The date must have the following format: MM/dd/yyyy"
						labelMode="floating"
						stylingMode="outlined">
						{/* <Validator validationGroup={sectionName}>
							<RequiredRule
								message="Date of birth is required"
							/>
							<RangeRule
								message="Date cannot be younger than the current date and earlier than 1900"
								min={MIN_DATE}
								max={CURRENT_DATE} />
							<RangeRule
								message={TEXT_DOB}
								max={MAX_DOB} />
						</Validator> */}
					</DateBox>
				</DialogContentRow>
				{inviteKey &&
					<DialogContentRow style={{ width: '100%' }} title="Invite link">
						<TextBox
							label="Invite link"
							value={`${document.location.host}/auth/invite?id=` + inviteKey}
							labelMode="floating"
							stylingMode="outlined"
							inputAttr={{ autocomplete: 'new-inviteKey' }}
						>
						</TextBox>
					</DialogContentRow>
				}
			</DialogContent>
			<DialogActions>
				<Button
					text="Create Link"
					type="default"
					stylingMode="contained"
					validationGroup={sectionName}
					disabled={!!inviteKey}
					onClick={onClickCreateLink} />
				<Button
					text="Send Email"
					type="default"
					stylingMode="contained"
					disabled={inviteKey ? false : true}
					onClick={onClickSendEmail} />
				{/* <Button
					text="Send SMS"
					type="default"
					stylingMode="contained"
					disabled={inviteKey ? false : true} /> */}
				<Button
					text="Cancel"
					type="default"
					stylingMode="outlined"
					onClick={onClickClose} />
			</DialogActions>
		</Dialog >
	);
};

export default GenerationInvites;