import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { selectSignPicture, selectLoading, selectAddress, selectApplicantMayQualify, selectApplied, selectCity, selectDate, selectDateOfBirth, selectDateOfBirthInsured, selectDiscount, selectEffectiveDate, selectEligibilityDate, selectEmployer, selectEmployment, selectFirstName, selectHealthInsurance, selectHousehold, selectLastName, selectMedicaid, selectMedicare, selectMiddleName, selectMonths, selectOtherProofOfIncome, selectOtherVerification, selectPhone, selectPrivateHealthInsurance, selectProofOfIncome, selectRenewalDate, selectSSN, selectState, selectTotalIncome, selectVerification, selectVerifiedBy, selectVerifiedDate, selectWhoInsurance, selectWorkedOnBoat, selectWorkedOnBoatContact, selectWorkPhone, selectZip, selectFileProofOfIncome, selectFrontCard, selectBackCard, selectDecline } from '../../../store/forms/discountSchedule/selectors';
import { setSignPicture, setLoading, setAddress, setApplicantMayQualify, setCity, setDate, setDateOfBirth, setDateOfBirthInsured, setDiscount, setEffectiveDate, setEligibilityDate, setEmployer, setEmployment, setFirstName, setHousehold, setLastName, setMiddleName, setMonths, setOtherProofOfIncome, setOtherVerification, setPhone, setProofOfIncome, setRenewalDate, setSSN, setState, setTotalIncome, setVerification, setVerifiedBy, setVerifiedDate, setWhoInsurance, setWorkedOnBoatContact, setWorkPhone, setZip, setApplied, setHealthInsurance, setMedicaid, setMedicare, setPrivateHealthInsurance, toggleWorkedOnBoat, setFileProofOfIncome, setFrontCard, setBackCard, toggleDecline } from '../../../store/forms/discountSchedule/slice';
import { completeDiscountSchedule } from '../../../store/forms/newPatientRegistrationPacket/slice';
import { setNewActiveStep } from '../../../store/forms/newPatientRegistrationPacket/middlewares';

import { getDiscountSchedule, saveDiscountSchedule, getDiscountScheduleByID, saveDiscountScheduleByID } from '../../../store/forms/discountSchedule/middlewares';
import { selectEmployments } from '../../../store/lookups/employment/selectors';
import { selectProofOfIncomes } from '../../../store/lookups/proofOfIncome/selectors';
import { selectStates } from '../../../store/lookups/state/selectors';

import { getProofOfIncome } from '../../../store/lookups/proofOfIncome/middlewares';
import { getEmployments } from '../../../store/lookups/employment/middlewares';
import { getState } from '../../../store/lookups/state/middlewares';

import { DiscountSchedule } from './DiscountSchedule';
import { useDispatch, useSelector } from "react-redux";
import { validateGroup, downloadFile } from '../../../utils/helper';
import { useEffect, useState } from 'react';
import { BASE_URL, USER_PATH } from '../../../utils/constants';
import { get } from '../../../core/rest';
import { showToast } from '../../Main/MainContainer';

const answers = [
	{
		"Oid": true,
		"Name": "Yes",
	}, {
		"Oid": false,
		"Name": "No",
	},
];

const verifications = [
	{
		"Oid": 1,
		"Name": "Pay Stubs",
	},
	{
		"Oid": 2,
		"Name": "Tax Forms",
	},
	{
		"Oid": 3,
		"Name": "Other",
	},
];

const applicantMayQualifies = [
	{
		"Oid": 1,
		"Name": "Medicaid",
	},
	{
		"Oid": 2,
		"Name": "Denali Kid Care",
	},
	{
		"Oid": 3,
		"Name": "Approved",
	},
	{
		"Oid": 4,
		"Name": "Not qualified-Over Income",
	},
];

const discounts = [
	{
		"Oid": 1,
		"Name": "100%",
	},
	{
		"Oid": 2,
		"Name": "75%",
	},
	{
		"Oid": 3,
		"Name": "50%",
	},
	{
		"Oid": 4,
		"Name": "25%",
	},
	{
		"Oid": 5,
		"Name": "Nominal Fee",
	},
];

export const DiscountScheduleContainer = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id } = useParams();

	const [signature, setSignature] = useState({});
	const signPicture = useSelector(selectSignPicture);

	const [error, setError] = useState(false);
	const loading = useSelector(selectLoading);

	const states = useSelector(selectStates);
	const employments = useSelector(selectEmployments);
	const proofOfIncomes = useSelector(selectProofOfIncomes);

	const decline = useSelector(selectDecline);
	const firstName = useSelector(selectFirstName);
	const middleName = useSelector(selectMiddleName);
	const lastName = useSelector(selectLastName);
	const SSN = useSelector(selectSSN);
	const date = useSelector(selectDate);
	const phone = useSelector(selectPhone);
	const dateOfBirth = useSelector(selectDateOfBirth);
	const address = useSelector(selectAddress);
	const city = useSelector(selectCity);
	const state = useSelector(selectState);
	const zip = useSelector(selectZip);
	const employment = useSelector(selectEmployment);
	const employer = useSelector(selectEmployer);
	const workPhone = useSelector(selectWorkPhone);
	const months = useSelector(selectMonths);
	const workedOnBoat = useSelector(selectWorkedOnBoat);
	const workedOnBoatContact = useSelector(selectWorkedOnBoatContact);
	const proofOfIncome = useSelector(selectProofOfIncome);
	const otherProofOfIncome = useSelector(selectOtherProofOfIncome);
	const fileProofOfIncome = useSelector(selectFileProofOfIncome);
	const totalIncome = useSelector(selectTotalIncome);
	const household = useSelector(selectHousehold);
	const healthInsurance = useSelector(selectHealthInsurance);
	const privateHealthInsurance = useSelector(selectPrivateHealthInsurance);
	const medicare = useSelector(selectMedicare);
	const medicaid = useSelector(selectMedicaid);
	const applied = useSelector(selectApplied);
	const effectiveDate = useSelector(selectEffectiveDate);
	const whoInsurance = useSelector(selectWhoInsurance);
	const dateOfBirthInsured = useSelector(selectDateOfBirthInsured);
	const frontCard = useSelector(selectFrontCard);
	const backCard = useSelector(selectBackCard);
	const verification = useSelector(selectVerification);
	const otherVerification = useSelector(selectOtherVerification);
	const applicantMayQualify = useSelector(selectApplicantMayQualify);
	const eligibilityDate = useSelector(selectEligibilityDate);
	const renewalDate = useSelector(selectRenewalDate);
	const discount = useSelector(selectDiscount);
	const verifiedBy = useSelector(selectVerifiedBy);
	const verifiedDate = useSelector(selectVerifiedDate);

	useEffect(() => {
		dispatch(setLoading(true));

		dispatch(getProofOfIncome());
		dispatch(getEmployments());
		dispatch(getState());
		dispatch(id ? getDiscountScheduleByID(id) : getDiscountSchedule());
	}, [dispatch, id]);

	useEffect(() => {
		if (!!signature?.fromDataURL && !!signature?.clear && signPicture) {
			signature.clear();
			signature.fromDataURL(signPicture);
		};
	}, [signPicture, signature]);

	const onToggleDecline = (event) => {
		dispatch(toggleDecline());
		if (event.value) {
			dispatch(id ? saveDiscountScheduleByID(id) : saveDiscountSchedule());
			dispatch(completeDiscountSchedule(true));
			dispatch(setNewActiveStep());
		}
	}

	const onRowInsertingHousehold = (event) => {
		const newValue = [...household, {
			id: new Date().toISOString(),
			name: event.data?.name,
			relationship: event.data?.relationship,
			dateOfBirth: event.data?.dateOfBirth,
			income: event.data?.income
		}];

		event.cancel = true;
		event.component.cancelEditData();
		dispatch(setHousehold(newValue));
	}

	const onRowRemovingHousehold = (event) => {
		let newValue = [...household];
		const newId = newValue.findIndex(el => el.id === event.data?.id);
		newValue.splice(newId, 1);

		event.cancel = true;
		event.component.cancelEditData();
		dispatch(setHousehold(newValue));
	}

	const onRowUpdatingHousehold = (event) => {
		let newValue = [...household];
		const newId = household.findIndex(el => el.id === event.oldData?.id);
		newValue[newId] = { ...household[newId], ...event.newData };

		event.cancel = true;
		event.component.cancelEditData();
		dispatch(setHousehold(newValue));
	}

	const onChangeFirstName = (event) => {
		dispatch(setFirstName(event.value.trim()));
	}

	const onChangeMiddleName = (event) => {
		dispatch(setMiddleName(event.value.trim()));
	}

	const onChangeLastName = (event) => {
		dispatch(setLastName(event.value.trim()));
	}

	const onChangeSSN = (event) => {
		dispatch(setSSN(event.value));
	}

	const onChangeDate = (event) => {
		dispatch(setDate(event.value));
	}

	const onChangePhone = (event) => {
		dispatch(setPhone(event.value));
	}

	const onChangeDateOfBirth = (event) => {
		dispatch(setDateOfBirth(event.value));
	}

	const onChangeAddress = (event) => {
		dispatch(setAddress(event.value.trim()));
	}

	const onChangeCity = (event) => {
		dispatch(setCity(event.value.trim()));
	}

	const onChangeState = (event) => {
		dispatch(setState(event.value));
	}

	const onChangeZip = (event) => {
		dispatch(setZip(event.value));
	}

	const onChangeEmployment = (event) => {
		dispatch(setEmployment(event.value));
	}

	const onChangeEmployer = (event) => {
		dispatch(setEmployer(event.value));
	}

	const onChangeWorkPhone = (event) => {
		dispatch(setWorkPhone(event.value));
	}

	const onChangeMonths = (event) => {
		dispatch(setMonths(event.value));
	}

	const onToggleWorkedOnBoat = () => {
		dispatch(toggleWorkedOnBoat());
	}

	const onChangeWorkedOnBoatContact = (event) => {
		dispatch(setWorkedOnBoatContact(event.value.trim()));
	}

	const onChangeProofOfIncome = (event) => {
		dispatch(setProofOfIncome(event.value));
	}

	const onChangeOtherProofOfIncome = (event) => {
		dispatch(setOtherProofOfIncome(event.value));
	}

	const onSelectFileProofOfIncome = async () => {
		const result = await get(BASE_URL + `/FileUploader/findLast/8`);

		const data = JSON.stringify({
			id: result.id,
			fileName: result.fileName,
			size: result.size,
		});

		dispatch(setFileProofOfIncome(data));
	}

	const onChangeTotalIncome = (event) => {
		dispatch(setTotalIncome(event.value));
	}

	const onChangeHealthInsurance = (event) => {
		dispatch(setHealthInsurance(event.value));
	}

	const onChangePrivateHealthInsurance = (event) => {
		dispatch(setPrivateHealthInsurance(event.value));
	}

	const onChangeMedicare = (event) => {
		dispatch(setMedicare(event.value));
	}

	const onChangeMedicaid = (event) => {
		dispatch(setMedicaid(event.value));
	}

	const onChangeApplied = (event) => {
		dispatch(setApplied(event.value));
	}

	const onChangeEffectiveDate = (event) => {
		dispatch(setEffectiveDate(event.value));
	}

	const onChangeWhoInsurance = (event) => {
		dispatch(setWhoInsurance(event.value));
	}

	const onChangeDateOfBirthInsured = (event) => {
		dispatch(setDateOfBirthInsured(event.value));
	}

	const onSelectFrontCard = async () => {
		const result = await get(BASE_URL + "/FileUploader/findLast/2");

		const data = JSON.stringify({
			id: result.id,
			fileName: result.fileName,
			size: result.size,
		})

		dispatch(setFrontCard(data));
	};

	const onSelectBackCard = async () => {
		const result = await get(BASE_URL + "/FileUploader/findLast/3");

		const data = JSON.stringify({
			id: result.id,
			fileName: result.fileName,
			size: result.size,
		})

		dispatch(setBackCard(data));
	};

	const onChangeVerification = (event) => {
		dispatch(setVerification(event.value));
	}

	const onChangeOtherVerification = (event) => {
		dispatch(setOtherVerification(event.value));
	}

	const onChangeApplicantMayQualify = (event) => {
		dispatch(setApplicantMayQualify(event.value));
	}

	const onChangeEligibilityDate = (event) => {
		dispatch(setEligibilityDate(event.value));
	}

	const onChangeRenewalDate = (event) => {
		dispatch(setRenewalDate(event.value));
	}

	const onChangeDiscount = (event) => {
		dispatch(setDiscount(event.value));
	}

	const onChangeVerifiedBy = (event) => {
		dispatch(setVerifiedBy(event.value));
	}

	const onChangeVerifiedDate = (event) => {
		dispatch(setVerifiedDate(event.value));
	}

	const onInitSignature = (event) => {
		setSignature(event);
	}

	const onChangeSignature = () => {
		setError(false);
	}

	const onClearSignature = () => {
		if (!!signature.clear) {
			signature.clear();
		}
	};

	const onClickAttachedFile = (ID, fileName) => {
		get(BASE_URL + `/FileUploader/download/${ID}`, {
			Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('AUTH_TOKEN_DATA')).accessToken,
			responseType: 'blob',
		})
			.then((blob) => downloadFile(blob, fileName))
			.catch(() => showToast('error', `Unable to download ${fileName}`));
	};

	const onSaveDiscountSchedule = (event) => {
		const picture = signature.toDataURL('image/png');
		const isValid = validateGroup(event);

		if (signature.isEmpty()) {
			setError(true);
		}

		if (isValid && !signature.isEmpty()) {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});

			dispatch(setSignPicture(picture));
			dispatch(id ? saveDiscountScheduleByID(id) : saveDiscountSchedule());
			dispatch(completeDiscountSchedule(true));
			dispatch(setNewActiveStep());
		}
	}

	const onClickBack = () => {
		const path = location.state?.path ? location.state.path : window.localStorage.getItem(USER_PATH);
		navigate(`/main/users/${path}/profile/${id}`, { state: { indexTab: 2 } });
	};

	return (
		<DiscountSchedule
			loading={loading}
			error={error}
			states={states}
			employments={employments}
			proofOfIncomes={proofOfIncomes}
			verifications={verifications}
			applicantMayQualifies={applicantMayQualifies}
			discounts={discounts}
			answers={answers}
			decline={decline}
			firstName={firstName}
			middleName={middleName}
			lastName={lastName}
			SSN={SSN}
			date={date}
			phone={phone}
			dateOfBirth={dateOfBirth}
			address={address}
			city={city}
			state={state}
			zip={zip}
			employment={employment}
			employer={employer}
			workPhone={workPhone}
			months={months}
			workedOnBoat={workedOnBoat}
			workedOnBoatContact={workedOnBoatContact}
			proofOfIncome={proofOfIncome}
			otherProofOfIncome={otherProofOfIncome}
			fileProofOfIncome={fileProofOfIncome ? JSON.parse(fileProofOfIncome) : fileProofOfIncome}
			totalIncome={totalIncome}
			household={household}
			healthInsurance={healthInsurance}
			privateHealthInsurance={privateHealthInsurance}
			medicare={medicare}
			medicaid={medicaid}
			applied={applied}
			effectiveDate={effectiveDate}
			whoInsurance={whoInsurance}
			dateOfBirthInsured={dateOfBirthInsured}
			frontCard={frontCard ? JSON.parse(frontCard) : frontCard}
			backCard={backCard ? JSON.parse(backCard) : backCard}
			verification={verification}
			otherVerification={otherVerification}
			applicantMayQualify={applicantMayQualify}
			eligibilityDate={eligibilityDate}
			renewalDate={renewalDate}
			discount={discount}
			verifiedBy={verifiedBy}
			verifiedDate={verifiedDate}

			onToggleDecline={onToggleDecline}
			onChangeFirstName={onChangeFirstName}
			onChangeMiddleName={onChangeMiddleName}
			onChangeLastName={onChangeLastName}
			onChangeSSN={onChangeSSN}
			onChangeDate={onChangeDate}
			onChangePhone={onChangePhone}
			onChangeDateOfBirth={onChangeDateOfBirth}
			onChangeAddress={onChangeAddress}
			onChangeCity={onChangeCity}
			onChangeState={onChangeState}
			onChangeZip={onChangeZip}
			onChangeEmployment={onChangeEmployment}
			onChangeEmployer={onChangeEmployer}
			onChangeWorkPhone={onChangeWorkPhone}
			onChangeMonths={onChangeMonths}
			onToggleWorkedOnBoat={onToggleWorkedOnBoat}
			onChangeWorkedOnBoatContact={onChangeWorkedOnBoatContact}
			onChangeProofOfIncome={onChangeProofOfIncome}
			onChangeOtherProofOfIncome={onChangeOtherProofOfIncome}
			onSelectFileProofOfIncome={onSelectFileProofOfIncome}
			onChangeTotalIncome={onChangeTotalIncome}
			onRowInsertingHousehold={onRowInsertingHousehold}
			onRowRemovingHousehold={onRowRemovingHousehold}
			onRowUpdatingHousehold={onRowUpdatingHousehold}
			onChangeHealthInsurance={onChangeHealthInsurance}
			onChangePrivateHealthInsurance={onChangePrivateHealthInsurance}
			onChangeMedicare={onChangeMedicare}
			onChangeMedicaid={onChangeMedicaid}
			onChangeApplied={onChangeApplied}
			onChangeEffectiveDate={onChangeEffectiveDate}
			onChangeWhoInsurance={onChangeWhoInsurance}
			onChangeDateOfBirthInsured={onChangeDateOfBirthInsured}
			onSelectFrontCard={onSelectFrontCard}
			onSelectBackCard={onSelectBackCard}
			onChangeVerification={onChangeVerification}
			onChangeOtherVerification={onChangeOtherVerification}
			onChangeApplicantMayQualify={onChangeApplicantMayQualify}
			onChangeEligibilityDate={onChangeEligibilityDate}
			onChangeRenewalDate={onChangeRenewalDate}
			onChangeDiscount={onChangeDiscount}
			onChangeVerifiedBy={onChangeVerifiedBy}
			onChangeVerifiedDate={onChangeVerifiedDate}

			onInitSignature={onInitSignature}
			onChangeSignature={onChangeSignature}
			onClearSignature={onClearSignature}

			onClickAttachedFile={onClickAttachedFile}
			onSaveDiscountSchedule={onSaveDiscountSchedule}
			onClickBack={onClickBack}
		/>
	)
}