import { dateFormatForApi } from "../../../utils/formats";
import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../core/rest";

export const getGrievanceFeedback = createAsyncThunk(
    "getGrievanceFeedback",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + "/GrievanceFeedback");
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getGrievanceFeedbackByID = createAsyncThunk(
    "getGrievanceFeedbackByID",
    async (patienID, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/GrievanceFeedback/${patienID}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setGrievanceFeedback = createAsyncThunk(
    "setGrievanceFeedback",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + "/GrievanceFeedback", data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setGrievanceFeedbackByID = createAsyncThunk(
    "setGrievanceFeedbackByID",
    async ({ patienID, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/GrievanceFeedback/${patienID}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveGrievanceFeedback = () => (dispatch, getState) => {
    const store = getState().grievanceFeedbackForm;

    const data = {
        "name": store.name,
        "address": store.address,
        "phone": store.phone,
        "email": store.email,
        "typeСonnectionPhone": store.typeСonnectionPhone,
        "typeСonnectionEmail": store.typeСonnectionEmail,
        "typeСonnectionMail": store.typeСonnectionMail,
        "bestConnectionMorning": store.bestConnectionMorning,
        "bestConnectionAfternoon": store.bestConnectionAfternoon,
        "bestConnectionEvening": store.bestConnectionEvening,
        "statusPatientClient": store.statusPatientClient,
        "statusVisitor": store.statusVisitor,
        "commentTypeGreatJob": store.commentTypeGreatJob,
        "commentTypeInquiry": store.commentTypeInquiry,
        "commentTypeSuggestion": store.commentTypeSuggestion,
        "commentTypeComplaintGrievance": store.commentTypeComplaintGrievance,
        "commentTypeSlidingScale": store.commentTypeSlidingScale,
        "commentTypeOther": store.commentTypeOther,
        "commentTypeOtherText": store.commentTypeOtherText,
        "date": dateFormatForApi(store.date),
        "clinicDepartment": store.clinicDepartment,
        "experience": store.experience,
        "allowExperience": store.allowExperience,
    }

    return dispatch(setGrievanceFeedback(data));
};

export const saveGrievanceFeedbackByID = (patienID) => (dispatch, getState) => {
    const store = getState().grievanceFeedbackForm;

    const data = {
        "name": store.name,
        "address": store.address,
        "phone": store.phone,
        "email": store.email,
        "typeСonnectionPhone": store.typeСonnectionPhone,
        "typeСonnectionEmail": store.typeСonnectionEmail,
        "typeСonnectionMail": store.typeСonnectionMail,
        "bestConnectionMorning": store.bestConnectionMorning,
        "bestConnectionAfternoon": store.bestConnectionAfternoon,
        "bestConnectionEvening": store.bestConnectionEvening,
        "statusPatientClient": store.statusPatientClient,
        "statusVisitor": store.statusVisitor,
        "commentTypeGreatJob": store.commentTypeGreatJob,
        "commentTypeInquiry": store.commentTypeInquiry,
        "commentTypeSuggestion": store.commentTypeSuggestion,
        "commentTypeComplaintGrievance": store.commentTypeComplaintGrievance,
        "commentTypeSlidingScale": store.commentTypeSlidingScale,
        "commentTypeOther": store.commentTypeOther,
        "commentTypeOtherText": store.commentTypeOtherText,
        "date": dateFormatForApi(store.date),
        "clinicDepartment": store.clinicDepartment,
        "experience": store.experience,
        "allowExperience": store.allowExperience,
    }

    return dispatch(setGrievanceFeedbackByID({ patienID, data }));
};