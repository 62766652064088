import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { setLoading, setAddress, setCity, setContactEmail, setContactFullName, setContactPhone, setContactRelationship, setDateConsent, setDateOfBirth, toggleDrugUser, setEmail, setEmployer, setEmployerAddress, setEmployerCity, setEmployerState, setEmployerZip, setEmployment, setFamilyMembers, setFirstName, setGender, setGroupNumber, setHaveBeenInHospitalLast6Months, setHaveEverBeenInHospital, setHaveHadIllnessOrInjury, setIncomeEstimate, setInsuranceProvider, setLastName, setLivingWill, setMaritalStatus, setMiddleName, setNumberOfArrests, setNumberOfChildren, setNumberOfPeople, setOtherGender, setPhone, setPhysicalExam, setPolicyNumber, setPregnant, setSSN, setState, setZip, toggleAnyCustodyArrangements, toggleCanLeaveVoiceMessage, toggleContactedByMedicalRN } from '../../../store/forms/annualPatientRegistration/slice';

import { selectAdvHCareDirectiveStatuses } from "../../../store/lookups/advHCareDirectiveStatus/selectors";
import { selectTotalHouseholdIncomes } from "../../../store/lookups/totalHouseholdIncome/selectors";
import { selectHospitalInSixMonths } from "../../../store/lookups/hospitalInSixMonths/selectors";
import { selectHospitalResidentialTreatments } from "../../../store/lookups/hospitalResidentialTreatment/selectors";
import { selectIllnessInjury12months } from "../../../store/lookups/illnessInjury12months/selectors";
import { selectPregnantStatuses } from "../../../store/lookups/pregnantStatus/selectors";
import { selectMaritalStatuses } from "../../../store/lookups/maritalStatus/selectors";
import { selectEmployments } from '../../../store/lookups/employment/selectors';
import { selectGenders } from "../../../store/lookups/gender/selectors";
import { selectStates } from '../../../store/lookups/state/selectors';

import { getAnnualPatientRegistration, saveAnnualPatientRegistration, getAnnualPatientRegistrationByID, saveAnnualPatientRegistrationByID } from '../../../store/forms/annualPatientRegistration/middlewares';
import { getAdvHCareDirectiveStatuses } from "../../../store/lookups/advHCareDirectiveStatus/middlewares";
import { getTotalHouseholdIncomes } from "../../../store/lookups/totalHouseholdIncome/middlewares";
import { getHospitalInSixMonths } from '../../../store/lookups/hospitalInSixMonths/middlewares';
import { getHospitalResidentialTreatments } from '../../../store/lookups/hospitalResidentialTreatment/middlewares';
import { getIllnessInjury12months } from '../../../store/lookups/illnessInjury12months/middlewares';
import { getPregnantStatuses } from "../../../store/lookups/pregnantStatus/middlewares";
import { getMaritalStatuses } from "../../../store/lookups/maritalStatus/middlewares";
import { getEmployments } from '../../../store/lookups/employment/middlewares';
import { getGenders } from "../../../store/lookups/gender/middlewares";
import { getState } from '../../../store/lookups/state/middlewares';

import { AnnualPatientRegistration } from './AnnualPatientRegistration';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { showToast } from '../../Main/MainContainer';
import { isAdmin } from '../../../core/auth/authProvider';
import { USER_PATH } from '../../../utils/constants';
import { validateGroup } from '../../../utils/helper';

export const AnnualPatientRegistrationContainer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const states = useSelector(selectStates);
    const genders = useSelector(selectGenders);
    const employments = useSelector(selectEmployments);
    const maritalStatuses = useSelector(selectMaritalStatuses);
    const pregnantStatuses = useSelector(selectPregnantStatuses);
    const totalHouseholdIncomes = useSelector(selectTotalHouseholdIncomes);
    const hospitalInSixMonths = useSelector(selectHospitalInSixMonths);
    const hospitalResidentialTreatment = useSelector(selectHospitalResidentialTreatments);
    const illnessInjury12months = useSelector(selectIllnessInjury12months);
    const advHCareDirectiveStatuses = useSelector(selectAdvHCareDirectiveStatuses);

    const stateAnnualPatientRegistration = useSelector(state => state.annualPatientRegistration);
    const getEntityAnnualPatientRegistration = (name) => stateAnnualPatientRegistration[name];
    const familyMembers = getEntityAnnualPatientRegistration('familyMembers');

    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(id ? getAnnualPatientRegistrationByID(id) : getAnnualPatientRegistration());
        dispatch(getState());
        dispatch(getGenders());
        dispatch(getEmployments());
        dispatch(getMaritalStatuses());
        dispatch(getPregnantStatuses());
        dispatch(getTotalHouseholdIncomes());
        dispatch(getHospitalInSixMonths());
        dispatch(getHospitalResidentialTreatments());
        dispatch(getIllnessInjury12months());
        dispatch(getAdvHCareDirectiveStatuses());
    }, [dispatch, id]);

    const setMembers = (event, newValue) => {
        event.cancel = true;
        event.component.cancelEditData();
        dispatch(setFamilyMembers(newValue));
    };

    const onRowInsertingFamilyMembers = (event) => {
        const newValue = [...familyMembers, {
            id: new Date().toISOString(),
            name: event.data?.name,
            relationship: event.data?.relationship,
            age: event.data?.age
        }];
        setMembers(event, newValue);
    }

    const onRowRemovingFamilyMembers = (event) => {
        let newValue = [...familyMembers];
        const newId = newValue.findIndex(el => el.id === event.data?.id);
        newValue.splice(newId, 1);
        setMembers(event, newValue);
    }

    const onRowUpdatingFamilyMembers = (event) => {
        let newValue = [...familyMembers];
        const newId = familyMembers.findIndex(el => el.id === event.oldData?.id);
        newValue[newId] = { ...familyMembers[newId], ...event.newData };
        setMembers(event, newValue);
    }

    const FN_TO_CALL = {
        FirstName: setFirstName,
        MiddleName: setMiddleName,
        LastName: setLastName,
        DateOfBirth: setDateOfBirth,
        SSN: setSSN,
        Gender: setGender,
        OtherGender: setOtherGender,
        Email: setEmail,
        Phone: setPhone,
        Address: setAddress,
        City: setCity,
        State: setState,
        Zip: setZip,
        MaritalStatus: setMaritalStatus,
        NumberOfPeople: setNumberOfPeople,
        NumberOfChildren: setNumberOfChildren,
        CanLeaveVoiceMessage: toggleCanLeaveVoiceMessage,
        AnyCustodyArrangements: toggleAnyCustodyArrangements,
        ContactFullName: setContactFullName,
        ContactRelationship: setContactRelationship,
        ContactEmail: setContactEmail,
        ContactPhone: setContactPhone,
        InsuranceProvider: setInsuranceProvider,
        PolicyNumber: setPolicyNumber,
        GroupNumber: setGroupNumber,
        IncomeEstimate: setIncomeEstimate,
        Employment: setEmployment,
        Employer: setEmployer,
        EmployerAddress: setEmployerAddress,
        EmployerCity: setEmployerCity,
        EmployerState: setEmployerState,
        EmployerZip: setEmployerZip,
        NumberOfArrests: setNumberOfArrests,
        Pregnant: setPregnant,
        DrugUser: toggleDrugUser,
        HaveEverBeenInHospital: setHaveEverBeenInHospital,
        HaveBeenInHospitalLast6Months: setHaveBeenInHospitalLast6Months,
        HaveHadIllnessOrInjury: setHaveHadIllnessOrInjury,
        PhysicalExam: setPhysicalExam,
        LivingWill: setLivingWill,
        DateConsent: setDateConsent,
        ContactedByMedicalRN: toggleContactedByMedicalRN,
    };

    const onChangeFunc = (name, event = null, trim = false) => {
        if (event) dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
        else dispatch(FN_TO_CALL[name]());
    };

    const onSaveAnnualPatientRegistration = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });

            dispatch(id ? saveAnnualPatientRegistrationByID(id) : saveAnnualPatientRegistration()).then(() => {
                !isAdmin() && navigate('/main/forms/all');
            }).catch(() => {
                showToast('error', 'Error to save Annual Patient Registration form');
            });
        };
    }

    const onClickBack = () => {
        const path = location.state?.path ? location.state.path : window.localStorage.getItem(USER_PATH);
        navigate(`/main/users/${path}/profile/${id}`, { state: { indexTab: 2 } });
    };

    return (
        <AnnualPatientRegistration
            states={states}
            genders={genders}
            employments={employments}
            maritalStatuses={maritalStatuses}
            pregnantStatuses={pregnantStatuses}
            totalHouseholdIncomes={totalHouseholdIncomes}
            hospitalInSixMonths={hospitalInSixMonths}
            hospitalResidentialTreatment={hospitalResidentialTreatment}
            illnessInjury12months={illnessInjury12months}
            advHCareDirectiveStatuses={advHCareDirectiveStatuses}

            getEntityAnnualPatientRegistration={getEntityAnnualPatientRegistration}
            onChangeFunc={onChangeFunc}

            onRowInsertingFamilyMembers={onRowInsertingFamilyMembers}
            onRowRemovingFamilyMembers={onRowRemovingFamilyMembers}
            onRowUpdatingFamilyMembers={onRowUpdatingFamilyMembers}

            onSaveAnnualPatientRegistration={onSaveAnnualPatientRegistration}
            onClickBack={onClickBack}
        />
    );
}