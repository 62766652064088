import { selectECommunicationConsent, selectIdAndInsuranceCard, selectNewPatientRegistration, selectNoticeOfPrivacyPractices } from '../../../../store/forms/newPatientRegistrationPacket/selectors';
import { completeSubmitForms } from '../../../../store/forms/newPatientRegistrationPacket/slice';

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { SubmitForms } from './SubmitForms';

export const SubmitFormsContainer = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const newPatientRegistration = useSelector(selectNewPatientRegistration);
    const noticeOfPrivacyPractices = useSelector(selectNoticeOfPrivacyPractices);
    const idAndInsuranceCard = useSelector(selectIdAndInsuranceCard);
    const eCommunicationConsent = useSelector(selectECommunicationConsent);

    const isCompleted = newPatientRegistration && noticeOfPrivacyPractices && idAndInsuranceCard && eCommunicationConsent;

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        dispatch(completeSubmitForms(true));
        navigate("/main/forms/new-patient-registration-packet/completed");
    }
    return (
        <SubmitForms
            isCompleted={isCompleted}
            handleClick={handleClick}
        />
    );
}
