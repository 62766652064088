import { Wrapper, Item, Label, TextBox, NumberBox, DateBox, SelectBox, CheckBox, Buttons } from '../../BasicInformation.style';
//import { MAX_WIDTH, MIN_DATE, CURRENT_DATE, MAX_DOB, TEXT_DOB, PATIENT_INFO, PHONE_MASK, SSN_MASK, ZIP_MASK, GRID_XS } from '../../../../../../utils/constants';
import { MAX_WIDTH, PATIENT_INFO, PHONE_MASK, SSN_MASK, ZIP_MASK, GRID_XS } from '../../../../../../utils/constants';
import { ADDRESS_RULE, CITY_RULE, GENDER_RULE, NAME_RULE } from '../../../../../../utils/rules';
import { dateFormatFromApi } from '../../../../../../utils/formats';
import { onKeyDownHandle } from '../../../../../../utils/helper';
import { useResizeDetector } from 'react-resize-detector';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	EmailRule,
	PatternRule,
	StringLengthRule,
	RangeRule,
	CustomRule,
} from 'devextreme-react/validator';

const setSizeByWidth = (width) => {
	switch (true) {
		case (width <= 600): return 12;
		case (width > 600 && width <= 900): return 6;
		case (width > 900 && width <= 1200): return 4;
		case (width > 1200 && width <= 1500): return 3;
		case (width > 1500): return 2;
		default: return 12;
	};
};

export const PatientInformation = ({
	showButton,

	genders,
	states,
	maritalStatuses,
	disabledEmail,

	onChangeFunc,
	getEntityPatientProfilePatientInfo,
	onCancelPatientInfo,
	onSavePatientInfo,
	validateEmail,
	validateMessage,
}) => {
	const { width, ref } = useResizeDetector();
	const [size, setSize] = useState(12);

	useEffect(() => {
		setSize(setSizeByWidth(width));
	}, [width]);

	return (
		<Wrapper id={PATIENT_INFO} ref={ref} maxWidth={MAX_WIDTH} >
			<LoadPanel
				visible={getEntityPatientProfilePatientInfo('loading')}
				position={{ of: `#${PATIENT_INFO}` }} />
			{
				!getEntityPatientProfilePatientInfo('loading') &&
				<Grid container spacing={2}>
					<Item item sm={size}>
						<Tooltip title="First Name *">
							<span>
								<Label>First Name:</Label>
								<TextBox
									label="First Name *"
									value={getEntityPatientProfilePatientInfo('firstName')}
									onValueChanged={e => onChangeFunc('FirstName', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-firstName' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="First Name is required"
										/>
										<PatternRule
											message="Do not use digits in the First Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="Middle Name">
							<span>
								<Label>Middle Name:</Label>
								<TextBox
									label="Middle Name"
									value={getEntityPatientProfilePatientInfo('middleName')}
									onValueChanged={e => onChangeFunc('MiddleName', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-middleName' }}>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="Last Name *">
							<span>
								<Label>Last Name:</Label>
								<TextBox
									label="Last Name *"
									value={getEntityPatientProfilePatientInfo('lastName')}
									onValueChanged={e => onChangeFunc('LastName', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-lastName' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Last Name is required"
										/>
										<PatternRule
											message="Do not use digits in the Last Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="SSN *">
							<span>
								<Label>SSN:</Label>
								<TextBox
									label="SSN *"
									value={getEntityPatientProfilePatientInfo('SSN')}
									onValueChanged={e => onChangeFunc('SSN', e)}
									labelMode="hidden"
									stylingMode="underlined"
									maskChar="."
									onKeyDown={onKeyDownHandle}
									mask={SSN_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="SSN must be in the correct format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-ssh' }}>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="SSN is required"
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="Date of Birth">
							<span>
								<Label>Date of Birth:</Label>
								<DateBox
									type="date"
									label="Date of Birth"
									value={dateFormatFromApi(getEntityPatientProfilePatientInfo('dateOfBirth'))}
									onValueChanged={e => onChangeFunc('DateOfBirth', e)}
									useMaskBehavior={true}
									showDropDownButton={false}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="hidden"
									stylingMode="underlined"
								>
									{/* <Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Date of birth is required"
										/>
										<RangeRule
											message="Date of Birth cannot be younger than the current date and earlier than 1900"
											min={MIN_DATE}
											max={CURRENT_DATE} />
										<RangeRule
											message={TEXT_DOB}
											max={MAX_DOB} />
									</Validator> */}
								</DateBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="Gender *">
							<span>
								<Label>Gender:</Label>
								<SelectBox
									label="Gender *"
									defaultValue={getEntityPatientProfilePatientInfo('gender')}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={genders}
									onValueChanged={e => onChangeFunc('Gender', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-gender' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Gender is required"
										/>
										<RangeRule
											message="Gender is required"
											min={0} />
									</Validator>
								</SelectBox>
							</span>
						</Tooltip>
					</Item>
					{
						getEntityPatientProfilePatientInfo('gender') === (genders.find((e) => e?.Name === "Other")?.Oid) &&
						<Item item sm={size}>
							<Tooltip title="Identify As *">
								<span>
									<Label>Identify As:</Label>
									<TextBox
										label="Identify As *"
										value={getEntityPatientProfilePatientInfo('otherGender')}
										onValueChanged={e => onChangeFunc('OtherGender', e, true)}
										maxLength={140}
										labelMode="hidden"
										stylingMode="underlined"
										inputAttr={{ autocomplete: 'new-otherGender' }}
									>
										<Validator validationGroup={PATIENT_INFO}>
											<RequiredRule
												message="Identify As is required"
											/>
											<PatternRule
												message="Identify As must be in the correct format"
												pattern={GENDER_RULE}
											/>
										</Validator>
									</TextBox>
								</span>
							</Tooltip>
						</Item>
					}
					<Item item sm={size}>
						<Tooltip title="Address *">
							<span>
								<Label>Address:</Label>
								<TextBox
									label="Address *"
									value={getEntityPatientProfilePatientInfo('address')}
									onValueChanged={e => onChangeFunc('Address', e, true)}
									maxLength={150}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-address' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Address is required"
										/>
										<PatternRule
											message="Address must be in the correct format"
											pattern={ADDRESS_RULE}
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="City *">
							<span>
								<Label>City:</Label>
								<TextBox
									label="City *"
									value={getEntityPatientProfilePatientInfo('city')}
									onValueChanged={e => onChangeFunc('City', e, true)}
									maxLength={20}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-city' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="City is required"
										/>
										<StringLengthRule
											message="City must have at least 4 symbols"
											min={4}
											max={20}
										/>
										<PatternRule
											message="City must be in the correct format"
											pattern={CITY_RULE}
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="State *">
							<span>
								<Label>State:</Label>
								<SelectBox
									label="State *"
									defaultValue={getEntityPatientProfilePatientInfo('state')}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={e => onChangeFunc('State', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-state' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="State is required"
										/>
										<RangeRule
											message="State is required"
											min={0} />
									</Validator>
								</SelectBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="Zip *">
							<span>
								<Label>Zip:</Label>
								<TextBox
									label="Zip *"
									value={getEntityPatientProfilePatientInfo('zip')}
									onValueChanged={e => onChangeFunc('Zip', e)}
									labelMode="hidden"
									stylingMode="underlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={ZIP_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="Zip must have at least 5 symbols"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-zip' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Zip is required"
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="Phone *">
							<span>
								<Label>Phone:</Label>
								<TextBox
									label="Phone *"
									value={getEntityPatientProfilePatientInfo('phone')}
									onValueChanged={e => onChangeFunc('Phone', e)}
									labelMode="hidden"
									stylingMode="underlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Phone is required"
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="Ok to leave voice message">
							<span>
								<Label>Ok to leave voice message:</Label>
								<CheckBox
									value={getEntityPatientProfilePatientInfo('canLeaveVoiceMessage')}
									onClick={() => onChangeFunc('CanLeaveVoiceMessage')} >
									{getEntityPatientProfilePatientInfo('canLeaveVoiceMessage') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="Ok to send reminders by text">
							<span>
								<Label>Ok to send reminders by text:</Label>
								<CheckBox
									value={getEntityPatientProfilePatientInfo('sendRemindersByText')}
									onClick={() => onChangeFunc('SendRemindersByText')} >
									{getEntityPatientProfilePatientInfo('sendRemindersByText') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="Email *">
							<span>
								<Label>Email:</Label>
								<TextBox
									disabled={disabledEmail}
									label="Email *"
									value={getEntityPatientProfilePatientInfo('email')}
									onValueChanged={e => onChangeFunc('Email', e, true)}
									maxLength={64}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-email' }}>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Email is required"
										/>
										<EmailRule
											message="Email is invalid"
										/>
										<StringLengthRule
											message="Email must be no more than 64 characters"
											min={1}
											max={64}
										/>
										<CustomRule
											validationCallback={validateEmail}
											message={validateMessage}
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="Ok to send email">
							<span>
								<Label>Ok to send email:</Label>
								<CheckBox
									value={getEntityPatientProfilePatientInfo('sendEmail')}
									onClick={() => onChangeFunc('SendEmail')} >
									{getEntityPatientProfilePatientInfo('sendEmail') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="Reliable internet access">
							<span>
								<Label>Reliable internet access: </Label>
								<CheckBox
									value={getEntityPatientProfilePatientInfo('internetAccess')}
									onClick={() => onChangeFunc('InternetAccess')} >
									{getEntityPatientProfilePatientInfo('internetAccess') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="# of people that live with patient">
							<span>
								<Label># of people that live with patient:</Label>
								<NumberBox
									label="# of people that live with patient:"
									value={getEntityPatientProfilePatientInfo('numberOfPeople') ? getEntityPatientProfilePatientInfo('numberOfPeople') : 0}
									onValueChanged={e => onChangeFunc('NumberOfPeople', e)}
									labelMode="hidden"
									stylingMode="underlined"
									showSpinButtons={true}
									min={0}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Number of Children is required"
										/>
									</Validator>
								</NumberBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="# of children under 18 in household">
							<span>
								<Label># of children under 18 in household:</Label>
								<NumberBox
									label="# of children under 18 in household:"
									value={getEntityPatientProfilePatientInfo('numberOfChildren') ? getEntityPatientProfilePatientInfo('numberOfChildren') : 0}
									onValueChanged={e => onChangeFunc('NumberOfChildren', e)}
									labelMode="hidden"
									stylingMode="underlined"
									showSpinButtons={true}
									min={0}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Number of Children is required"
										/>
									</Validator>
								</NumberBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={size}>
						<Tooltip title="Marital Status *">
							<span>
								<Label>Marital Status:</Label>
								<SelectBox
									label="Marital Status *"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityPatientProfilePatientInfo('maritalStatus')}
									dataSource={maritalStatuses}
									onValueChanged={e => onChangeFunc('MaritalStatus', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-maritalStatus' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Marital Status is required"
										/>
										<RangeRule
											message="Marital Status is required"
											min={0} />
									</Validator>
								</SelectBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={PATIENT_INFO}
								onClick={onSavePatientInfo} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelPatientInfo} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper>
	);
};
