import { setGuardian, setGuardianDate, setSigned, setSignedDate } from "../../../../../../store/users/patientProfile/basicInformation/consent/slice";
import { getConsentById, saveConsentById } from "../../../../../../store/users/patientProfile/basicInformation/consent/middlewares";

import { validateGroup } from "../../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { Consent } from "./Consent";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ConsentContainer = () => {
    const [signature, setSignature]   = useState({});
    const [showButton, setShowButton] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();

    const statePatientProfileConsent     = useSelector(state => state.patientProfileConsent);
	const getEntityPatientProfileConsent = (name) => statePatientProfileConsent[name];

    const signPicture = getEntityPatientProfileConsent('signPicture');

    useEffect(() => {
        dispatch(getConsentById(id))
    }, [dispatch, id])

    useEffect(() => {
		if (!!signature?.fromDataURL && !!signature?.clear && signPicture) {
			signature.clear();
			signature.fromDataURL(signPicture);
		};
	}, [signPicture, signature]);

    const FN_TO_CALL = {
        Signed: setSigned,
        Guardian: setGuardian,
        SignedDate: setSignedDate,
        GuardianDate: setGuardianDate,
    };

    const onChangeFunc = (name, event = null, trim = false) => {
        if(event) dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
        else dispatch(FN_TO_CALL[name]());
        setShowButton(true);
    };

    const onInitSignature = (event) => {
		setSignature(event);
	}

    const onCancelConsent = () => {
        dispatch(getConsentById(id));
        setShowButton(false);
    }

    const onSaveConsent = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(saveConsentById(id));
            setShowButton(false);
        }
    }

    return (
        <Consent
            showButton={showButton}

            getEntityPatientProfileConsent={getEntityPatientProfileConsent}
            onChangeFunc={onChangeFunc}
            onInitSignature={onInitSignature}

            onCancelConsent={onCancelConsent}
            onSaveConsent={onSaveConsent}
        />
    );
}