//import { MAX_WIDTH, MIN_DATE, CURRENT_DATE, MAX_DOB, TEXT_DOB, PATIENT_INFO, SSN_MASK, ZIP_MASK, PHONE_MASK, GRID_XS, GRID_SM, GRID_MD } from '../../../../../utils/constants';
import { MAX_WIDTH, PATIENT_INFO, SSN_MASK, ZIP_MASK, PHONE_MASK, GRID_XS, GRID_SM, GRID_MD } from '../../../../../utils/constants';
import { ADDRESS_RULE, CITY_RULE, GENDER_RULE, NAME_RULE } from '../../../../../utils/rules';
import { Wrapper, CheckboxGrid } from '../../../../../components/Main/Main.style';
import { dateFormatFromApi } from '../../../../../utils/formats';
import { onKeyDownHandle } from '../../../../../utils/helper';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import NumberBox from 'devextreme-react/number-box';
import TextBox from 'devextreme-react/text-box';
import DateBox from 'devextreme-react/date-box';
import CheckBox from 'devextreme-react/check-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	EmailRule,
	PatternRule,
	StringLengthRule,
	RangeRule,
	CustomRule,
} from 'devextreme-react/validator';

export const PatientInformation = ({
	loading,

	genders,
	states,
	maritalStatuses,

	firstName,
	middleName,
	lastName,
	SSN,
	dateOfBirth,
	gender,
	otherGender,
	address,
	city,
	state,
	zip,
	phone,
	email,
	numberOfPeople,
	numberOfChildren,
	maritalStatus,
	sendEmail,
	internetAccess,
	canLeaveVoiceMessage,
	sendRemindersByText,
	validateEmail,
	validateMessage,
	disabledEmail,

	onChangeFirstName,
	onChangeMiddleName,
	onChangeLastName,
	onChangeSSN,
	onChangeDateOfBirth,
	onChangeGender,
	onChangeOtherGender,
	onChangeAddress,
	onChangeCity,
	onChangeState,
	onChangeZip,
	onChangePhone,
	onChangeEmail,
	onChangeNumberOfPeople,
	onChangeNumberOfChildren,
	onChangeMaritalStatus,
	onToggleSendEmail,
	onToggleInternetAccess,
	onToggleCanLeaveVoiceMessage,
	onToggleSendRemindersByText,

	onSavePatientInfo,
}) => {

	return (
		<Wrapper id={PATIENT_INFO} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${PATIENT_INFO}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="First Name *">
							<>
								<TextBox
									label="First Name *"
									value={firstName}
									onValueChanged={onChangeFirstName}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-firstName' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="First Name is required"
										/>
										<PatternRule
											message="Do not use digits in the First Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Middle Name">
							<>
								<TextBox
									label="Middle Name"
									value={middleName}
									onValueChanged={onChangeMiddleName}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-middleName' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Last Name *">
							<>
								<TextBox
									label="Last Name *"
									value={lastName}
									onValueChanged={onChangeLastName}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-lastName' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Last Name is required"
										/>
										<PatternRule
											message="Do not use digits in the Last Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="SSN *">
							<>
								<TextBox
									label="SSN *"
									value={SSN}
									onValueChanged={onChangeSSN}
									labelMode="floating"
									stylingMode="outlined"
									maskChar="."
									onKeyDown={onKeyDownHandle}
									mask={SSN_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="SSN must be in the correct format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-ssn' }}>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="SSN is required"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Date of Birth *">
							<>
								<DateBox
									type="date"
									label="Date of Birth *"
									value={dateFormatFromApi(dateOfBirth)}
									onValueChanged={onChangeDateOfBirth}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									{/* <Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Date of birth is required"
										/>
										<RangeRule
											message="Date cannot be younger than the current date and earlier than 1900"
											min={MIN_DATE}
											max={CURRENT_DATE} />
										<RangeRule
											message={TEXT_DOB}
											max={MAX_DOB} />
									</Validator> */}
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Gender *">
							<>
								<SelectBox
									label="Gender *"
									defaultValue={gender}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={genders}
									onValueChanged={onChangeGender}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-gender' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Gender is required"
										/>
										<RangeRule
											message="Gender is required"
											min={0} />
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					{
						gender === (genders.find((e) => e?.Name === "Other")?.Oid) ?
							<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
								<Tooltip title="Identify As *">
									<>
										<TextBox
											label="Identify As *"
											value={otherGender}
											onValueChanged={onChangeOtherGender}
											maxLength={140}
											labelMode="floating"
											stylingMode="outlined"
											inputAttr={{ autocomplete: 'new-otherGender' }}
										>
											<Validator validationGroup={PATIENT_INFO}>
												<RequiredRule
													message="Identify As is required"
												/>
												<PatternRule
													message="Identify As must be in the correct format"
													pattern={GENDER_RULE}
												/>
											</Validator>
										</TextBox>
									</>
								</Tooltip>
							</Grid>
							: ""
					}
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Address *">
							<>
								<TextBox
									label="Address *"
									value={address}
									onValueChanged={onChangeAddress}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-address' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Address is required"
										/>
										<PatternRule
											message="Address must be in the correct format"
											pattern={ADDRESS_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="City *">
							<>
								<TextBox
									label="City *"
									value={city}
									onValueChanged={onChangeCity}
									maxLength={20}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-city' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="City is required"
										/>
										<StringLengthRule
											message="City must have at least 4 symbols"
											min={4}
											max={20}
										/>
										<PatternRule
											message="City must be in the correct format"
											pattern={CITY_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="State *">
							<>
								<SelectBox
									label="State *"
									defaultValue={state}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={onChangeState}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-state' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="State is required"
										/>
										<RangeRule
											message="State is required"
											min={0} />
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Zip *">
							<>
								<TextBox
									label="Zip *"
									value={zip}
									onValueChanged={onChangeZip}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={ZIP_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="Zip must have at least 5 symbols"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-zip' }}>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule message="Zip is required" />
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Phone *">
							<>
								<TextBox
									label="Phone *"
									value={phone}
									onValueChanged={onChangePhone}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Phone is required"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<CheckboxGrid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Can APIA leave a confidential voice message?">
							<>
								<CheckBox
									text="Can APIA leave a confidential voice message?"
									value={canLeaveVoiceMessage}
									onValueChanged={onToggleCanLeaveVoiceMessage} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<CheckboxGrid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Ok to send appt reminders by text?">
							<>
								<CheckBox
									text="Ok to send appt reminders by text?"
									value={sendRemindersByText}
									onValueChanged={onToggleSendRemindersByText} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Email *">
							<>
								<TextBox
									disabled={disabledEmail}
									label="Email *"
									value={email}
									onValueChanged={onChangeEmail}
									maxLength={64}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-email' }}>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Email is required"
										/>
										<EmailRule
											message="Email is invalid"
										/>
										<StringLengthRule
											message="Email must be no more than 64 characters"
											min={1}
											max={64}
										/>
										<CustomRule
											validationCallback={validateEmail}
											message={validateMessage}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<CheckboxGrid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Ok to send email? (Emails are not encrypted)">
							<>
								<CheckBox
									text="Ok to send email? (Emails are not encrypted)"
									value={sendEmail}
									onValueChanged={onToggleSendEmail} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<CheckboxGrid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Do you have a reliable internet access?">
							<>
								<CheckBox
									text="Do you have a reliable internet access?"
									value={internetAccess}
									onValueChanged={onToggleInternetAccess} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Number of People that live with you *">
							<>
								<NumberBox
									label="Number of People that live with you *"
									value={numberOfPeople ? numberOfPeople : 0}
									onValueChanged={onChangeNumberOfPeople}
									showSpinButtons={true}
									className="label__medium"
									labelMode="floating"
									stylingMode="outlined"
									min={0}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Number of Children is required"
										/>
									</Validator>
								</NumberBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Number of Children under 18 in Household *">
							<>
								<NumberBox
									label="Number of Children under 18 in Household *"
									value={numberOfChildren ? numberOfChildren : 0}
									onValueChanged={onChangeNumberOfChildren}
									showSpinButtons={true}
									className="label__medium"
									labelMode="floating"
									stylingMode="outlined"
									min={0}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Number of Children is required"
										/>
									</Validator>
								</NumberBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Marital Status *">
							<>
								<SelectBox
									label="Marital Status *"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={maritalStatus}
									dataSource={maritalStatuses}
									onValueChanged={onChangeMaritalStatus}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-maritalStatus' }}
								>
									<Validator validationGroup={PATIENT_INFO}>
										<RequiredRule
											message="Marital Status is required"
										/>
										<RangeRule
											message="Marital Status is required"
											min={0} />
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right', gap: '10px', display: 'flex', justifyContent: 'flex-end' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="outlined"
							validationGroup={PATIENT_INFO}
							onClick={onSavePatientInfo} />
						<Button
							text="Save and continue"
							type="default"
							stylingMode="contained"
							validationGroup={PATIENT_INFO}
							onClick={(e) => onSavePatientInfo(e, true)} />
					</Grid>
				</Grid>
			}
		</Wrapper >
	);
};