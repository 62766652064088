import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { setLoading, setAddress, setApptComment, setContact, setDateOfBirth, setDeclinedComment, setFirstAttempt, setFirstAttemptBy, setPerson, setPhone, setReason, setReferral, setReferralFollowedUpBy, setReferralFollowedUpDate, setRequest, setSecondAttempt, setSecondAttemptBy, setThirdAttempt, setThirdAttemptBy, toggleAgree, toggleAppt, toggleDeclined, toggleNoAnswer, toggleClosedReferral } from '../../../store/forms/referralForm/slice';
import { getReferralForm, saveReferralForm, getReferralFormByID, saveReferralFormByID } from '../../../store/forms/referralForm/middlewares';

import { ReferralForm } from './ReferralForm';
import { useDispatch, useSelector } from "react-redux";
import { validateGroup } from '../../../utils/helper';
import { useEffect } from 'react';
import { showToast } from '../../Main/MainContainer';
import { isAdmin } from '../../../core/auth/authProvider';
import { USER_PATH } from '../../../utils/constants';

export const ReferralFormContainer = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const stateReferralForm = useSelector(state => state.referralForm);
    const getEntityReferralForm = (name) => stateReferralForm[name];

    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(id ? getReferralFormByID(id) : getReferralForm());
    }, [dispatch, id]);

    const FN_TO_CALL = {
        Agree: toggleAgree,
        Person: setPerson,
        DateOfBirth: setDateOfBirth,
        Address: setAddress,
        Phone: setPhone,
        Referral: setReferral,
        Contact: setContact,
        Reason: setReason,
        Request: setRequest,
        Appt: toggleAppt,
        ApptComment: setApptComment,
        Declined: toggleDeclined,
        DeclinedComment: setDeclinedComment,
        NoAnswer: toggleNoAnswer,
        FirstAttempt: setFirstAttempt,
        FirstAttemptBy: setFirstAttemptBy,
        SecondAttempt: setSecondAttempt,
        SecondAttemptBy: setSecondAttemptBy,
        ThirdAttempt: setThirdAttempt,
        ThirdAttemptBy: setThirdAttemptBy,
        ClosedReferral: toggleClosedReferral,
        ReferralFollowedUpBy: setReferralFollowedUpBy,
        ReferralFollowedUpDate: setReferralFollowedUpDate,
    };

    const onChangeFunc = (name, event = null, trim = false) => {
        if (event) dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
        else dispatch(FN_TO_CALL[name]());
    };

    const onSaveReferralForm = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });

            dispatch(id ? saveReferralFormByID(id) : saveReferralForm()).then(() => {
                !isAdmin() && navigate('/main/forms/all');
            }).catch(() => {
                showToast('error', 'Error to save Referral to Behavioral Health form');
            });
        }
    }

    const onClickBack = () => {
        const path = location.state?.path ? location.state.path : window.localStorage.getItem(USER_PATH);
        navigate(`/main/users/${path}/profile/${id}`, { state: { indexTab: 2 } });
    };

    return (
        <ReferralForm
            getEntityReferralForm={getEntityReferralForm}

            onChangeFunc={onChangeFunc}

            onSaveReferralForm={onSaveReferralForm}
            onClickBack={onClickBack}
        />
    )
}