import { isAdmin } from '../../../core/auth/authProvider';
import { CURRENT_DATE, MAX_CURRENT_DATE, MAX_WIDTH, MIN_CURRENT_DATE, MIN_DATE, SSN_MASK, ZIP_MASK, PHONE_MASK, GRID_XS, GRID_SM, GRID_MD } from '../../../utils/constants';
import { Wrapper, TitleWrapper, Title, Text, OrderedList, Item, CheckboxGrid } from '../../../components/Main/Main.style';
import { CITY_RULE, NAME_RULE } from '../../../utils/rules';
import { dateFormatFromApi } from '../../../utils/formats';
import { onKeyDownHandle } from '../../../utils/helper';
import { Grid } from '@mui/material';

import DataGrid, { Column, Editing, Toolbar, Item as ItemToolbar } from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import NumberBox from 'devextreme-react/number-box';
import CheckBox from 'devextreme-react/check-box';
import DateBox from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	EmailRule,
	PatternRule,
	RangeRule,
} from 'devextreme-react/validator';

export const AnnualPatientRegistration = ({
	states,
	genders,
	employments,
	maritalStatuses,
	pregnantStatuses,
	totalHouseholdIncomes,
	hospitalInSixMonths,
	hospitalResidentialTreatment,
	illnessInjury12months,
	advHCareDirectiveStatuses,

	getEntityAnnualPatientRegistration,
	onChangeFunc,

	onRowInsertingFamilyMembers,
	onRowRemovingFamilyMembers,
	onRowUpdatingFamilyMembers,

	onSaveAnnualPatientRegistration,
	onClickBack,
}) => {
	const sectionName = "annualPatientRegistration";

	return (
		<Wrapper id={sectionName} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityAnnualPatientRegistration('loading')}
				position={{ of: `#${sectionName}` }} />
			{
				!getEntityAnnualPatientRegistration('loading') &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<TitleWrapper>
							{isAdmin() && <Button hint='Go back to forms' icon="back" onClick={onClickBack} />}
							<Tooltip title="Annual Patient Registration Update">
								<Title>Annual Patient Registration Update</Title>
							</Tooltip>
						</TitleWrapper>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="First Name *">
							<>
								<TextBox
									label="First Name *"
									value={getEntityAnnualPatientRegistration('firstName')}
									onValueChanged={(e) => onChangeFunc('FirstName', e, true)}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-firstName' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="First Name is required"
										/>
										<PatternRule
											message="Do not use digits in the First Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Middle Name">
							<>
								<TextBox
									label="Middle Name"
									value={getEntityAnnualPatientRegistration('middleName')}
									onValueChanged={(e) => onChangeFunc('MiddleName', e, true)}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-middleName' }}
								>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Last Name *">
							<>
								<TextBox
									label="Last Name *"
									value={getEntityAnnualPatientRegistration('lastName')}
									onValueChanged={(e) => onChangeFunc('LastName', e, true)}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-lastName' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Last Name is required"
										/>
										<PatternRule
											message="Do not use digits in the Last Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Date of Birth *">
							<>
								<DateBox
									label="Date of Birth *"
									type="date"
									value={dateFormatFromApi(getEntityAnnualPatientRegistration('dateOfBirth'))}
									onValueChanged={(e) => onChangeFunc('DateOfBirth', e)}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined">
									{/* <Validator validationGroup={sectionName}>
										<RequiredRule
											message="Date of birth is required"
										/>
										<RangeRule
											message="Date cannot be younger than the current date and earlier than 1900"
											min={MIN_DATE}
											max={CURRENT_DATE} />
										<RangeRule
											message={TEXT_DOB}
											max={MAX_DOB} />
									</Validator> */}
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="SSN *">
							<>
								<TextBox
									label="SSN *"
									value={getEntityAnnualPatientRegistration('SSN')}
									onValueChanged={(e) => onChangeFunc('SSN', e)}
									labelMode="floating"
									stylingMode="outlined"
									maskChar="."
									onKeyDown={onKeyDownHandle}
									mask={SSN_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="SSN must be in the correct format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-ssn' }}>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="SSN is required"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Gender *">
							<>
								<SelectBox
									label="Gender *"
									defaultValue={getEntityAnnualPatientRegistration('gender')}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={genders}
									onValueChanged={(e) => onChangeFunc('Gender', e)}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-gender' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Gender is required"
										/>
										<RangeRule
											message="Gender is required"
											min={0} />
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					{
						getEntityAnnualPatientRegistration('gender') === (genders.find((e) => e?.Name === "Other")?.Oid) ?
							<Grid item xs={GRID_XS}>
								<Tooltip title="Identify As">
									<>
										<TextBox
											label="Identify As"
											value={getEntityAnnualPatientRegistration('otherGender')}
											onValueChanged={(e) => onChangeFunc('OtherGender', e)}
											labelMode="floating"
											stylingMode="outlined"
											inputAttr={{ autocomplete: 'new-otherGender' }}
										>
											<Validator validationGroup={sectionName}>
												<RequiredRule
													message="Identify As is required"
												/>
											</Validator>
										</TextBox>
									</>
								</Tooltip>
							</Grid>
							: ""
					}
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Email *">
							<>
								<TextBox
									label="Email *"
									value={getEntityAnnualPatientRegistration('email')}
									onValueChanged={(e) => onChangeFunc('Email', e, true)}
									maxLength={64}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-email' }}>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Email is required"
										/>
										<EmailRule
											message="Email is invalid"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Phone *">
							<>
								<TextBox
									label="Phone *"
									value={getEntityAnnualPatientRegistration('phone')}
									onValueChanged={(e) => onChangeFunc('Phone', e)}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }}>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Phone is required"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<CheckboxGrid item xs={GRID_XS}>
						<Tooltip title="Can APIA leave a confidential voice message?">
							<>
								<CheckBox
									text="Can APIA leave a confidential voice message?"
									value={getEntityAnnualPatientRegistration('canLeaveVoiceMessage')}
									onValueChanged={() => onChangeFunc('CanLeaveVoiceMessage')} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Address *">
							<>
								<TextBox
									label="Address *"
									value={getEntityAnnualPatientRegistration('address')}
									onValueChanged={(e) => onChangeFunc('Address', e, true)}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-address' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Address is required"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="City *">
							<>
								<TextBox
									label="City *"
									value={getEntityAnnualPatientRegistration('city')}
									onValueChanged={(e) => onChangeFunc('City', e, true)}
									maxLength={20}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-city' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="City is required"
										/>
										<PatternRule
											message="Do not use digits in the City"
											pattern={CITY_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="State *">
							<>
								<SelectBox
									label="State *"
									defaultValue={getEntityAnnualPatientRegistration('state')}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={(e) => onChangeFunc('State', e)}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-state' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="State is required"
										/>
										<RangeRule
											message="State is required"
											min={0} />
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Zip *">
							<>
								<TextBox
									label="Zip *"
									value={getEntityAnnualPatientRegistration('zip')}
									onValueChanged={(e) => onChangeFunc('Zip', e)}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={ZIP_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="Zip must have at least 5 symbols"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-zip' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Zip is required"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Marital Status *">
							<>
								<SelectBox
									label="Marital Status *"
									defaultValue={getEntityAnnualPatientRegistration('maritalStatus')}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={maritalStatuses}
									onValueChanged={(e) => onChangeFunc('MaritalStatus', e)}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-maritalStatus' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Marital Status is required"
										/>
										<RangeRule
											message="Marital Status is required"
											min={0} />
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Number of People that live with you *">
							<>
								<NumberBox
									label="Number of People that live with you *"
									value={getEntityAnnualPatientRegistration('numberOfPeople') ? getEntityAnnualPatientRegistration('numberOfPeople') : 0}
									onValueChanged={(e) => onChangeFunc('NumberOfPeople', e)}
									showSpinButtons={true}
									className="label__medium"
									min={0}
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Number of People that live with you is required"
										/>
									</Validator>
								</NumberBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Number of Children under 18 in Household *">
							<>
								<NumberBox
									label="Number of Children under 18 in Household *"
									value={getEntityAnnualPatientRegistration('numberOfChildren') ? getEntityAnnualPatientRegistration('numberOfChildren') : 0}
									onValueChanged={(e) => onChangeFunc('NumberOfChildren', e)}
									showSpinButtons={true}
									className="label__medium"
									min={0}
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Number of Children is required"
										/>
									</Validator>
								</NumberBox>
							</>
						</Tooltip>
					</Grid>
					<CheckboxGrid item xs={GRID_XS}>
						<Tooltip title="For Minors: Are there any custody arrangements?">
							<>
								<CheckBox
									text="For Minors: Are there any custody arrangements?"
									value={getEntityAnnualPatientRegistration('anyCustodyArrangements')}
									onValueChanged={() => onChangeFunc('AnyCustodyArrangements')} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<Grid item xs={GRID_XS}>
						<Text>Emergency Contact Information:</Text>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Full Name *">
							<>
								<TextBox
									label="Full Name *"
									value={getEntityAnnualPatientRegistration('contactFullName')}
									onValueChanged={(e) => onChangeFunc('ContactFullName', e, true)}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-contactFullName' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Full Name is required"
										/>
										<PatternRule
											message="Do not use digits in the Full Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Relationship">
							<>
								<TextBox
									label="Relationship"
									value={getEntityAnnualPatientRegistration('contactRelationship')}
									onValueChanged={(e) => onChangeFunc('ContactRelationship', e)}
									maxLength={60}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-ontactRelationship' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Email">
							<>
								<TextBox
									label="Email"
									value={getEntityAnnualPatientRegistration('contactEmail')}
									onValueChanged={(e) => onChangeFunc('ContactEmail', e, true)}
									maxLength={64}
									labelMode="floating"
									stylingMode="outlined">
									<Validator validationGroup={sectionName}
										inputAttr={{ autocomplete: 'new-contactEmail' }}>
										<EmailRule
											message="Email is invalid"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Phone *">
							<>
								<TextBox
									label="Phone *"
									value={getEntityAnnualPatientRegistration('contactPhone')}
									onValueChanged={(e) => onChangeFunc('ContactPhone', e)}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-contactPhone' }}>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Phone is required"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Insurance Provider">
							<>
								<TextBox
									label="Insurance Provider"
									value={getEntityAnnualPatientRegistration('insuranceProvider')}
									onValueChanged={(e) => onChangeFunc('InsuranceProvider', e)}
									maxLength={60}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-insuranceProvider' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>Do you have any other insurance?</Text>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Policy Number">
							<>
								<TextBox
									label="Policy Number"
									value={getEntityAnnualPatientRegistration('policyNumber')}
									onValueChanged={(e) => onChangeFunc('PolicyNumber', e)}
									maxLength={60}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-policyNumber' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Group Number">
							<>
								<TextBox
									label="Group Number"
									value={getEntityAnnualPatientRegistration('groupNumber')}
									onValueChanged={(e) => onChangeFunc('GroupNumber', e)}
									maxLength={60}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-groupNumber' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text style={{ paddingBottom: '8px' }}>
							Please list ALL members (people) in the household (spouse, child, foster child, grandparent, aunt, uncle, etc.).
						</Text>
						<OrderedList>
							<Item>To add a person, click on the "Add a Row" button and start typing.</Item>
							<Item>To save your entry, click on the floppy disk icon at the end of a row or press "Enter".</Item>
							<Item>Once saved, you can come back and edit/delete an entry by clicking on the corresponding icon at the end of a row.</Item>
							<Item>Repeat steps 1 and 2 to add more people.</Item>
						</OrderedList>
					</Grid>
					<Grid item xs={GRID_XS}>
						<DataGrid
							dataSource={getEntityAnnualPatientRegistration('familyMembers')}
							showBorders={true}
							onRowInserting={onRowInsertingFamilyMembers}
							onRowRemoving={onRowRemovingFamilyMembers}
							onRowUpdating={onRowUpdatingFamilyMembers}>
							<Toolbar>
								<ItemToolbar location="before" name='addRowButton' showText />
							</Toolbar>
							<Editing
								mode="row"
								allowAdding={true}
								allowUpdating={true}
								allowDeleting={true}
								confirmDelete={false} />
							<Column dataField="name" caption="Full Name" alignment="left" >
								<RequiredRule message="" />
							</Column>
							<Column dataField="relationship" caption="Relationship" alignment="left" />
							<Column dataField="age" caption="Age" dataType="number" alignment="left" />
						</DataGrid>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Total Annual Household Income Estimate">
							<>
								<SelectBox
									label="Total Annual Household Income Estimate"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityAnnualPatientRegistration('incomeEstimate')}
									dataSource={totalHouseholdIncomes}
									onValueChanged={(e) => onChangeFunc('IncomeEstimate', e)}
									className="label__medium"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-totalHouseholdIncome' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Employment Status *">
							<>
								<SelectBox
									label="Employment Status *"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityAnnualPatientRegistration('employment')}
									dataSource={employments}
									onValueChanged={(e) => onChangeFunc('Employment', e)}
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-employment' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Employment Status is required"
										/>
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Employer">
							<>
								<TextBox
									label="Employer"
									value={getEntityAnnualPatientRegistration('employer')}
									onValueChanged={(e) => onChangeFunc('Employer', e, true)}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-employer' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Address">
							<>
								<TextBox
									label="Address"
									value={getEntityAnnualPatientRegistration('employerAddress')}
									onValueChanged={(e) => onChangeFunc('EmployerAddress', e, true)}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-employerAddress' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="City">
							<>
								<TextBox
									label="City"
									value={getEntityAnnualPatientRegistration('employerCity')}
									onValueChanged={(e) => onChangeFunc('EmployerCity', e, true)}
									maxLength={20}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-employerCity' }}>
									<Validator validationGroup={sectionName}>
										<PatternRule
											message="Do not use digits in the City"
											pattern={CITY_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="State">
							<>
								<SelectBox
									label="State"
									defaultValue={getEntityAnnualPatientRegistration('employerState')}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={(e) => onChangeFunc('EmployerState', e)}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-employerState' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Zip">
							<>
								<TextBox
									label="Zip"
									value={getEntityAnnualPatientRegistration('employerZip')}
									onValueChanged={(e) => onChangeFunc('EmployerZip', e)}
									labelMode="floating"
									stylingMode="outlined"
									maskChar="."
									mask={ZIP_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="Zip must have at least 5 symbols"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-employerZip' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Legal History: Number of Arrests in the past 30 days">
							<>
								<NumberBox
									label="Legal History: Number of Arrests in the past 30 days"
									value={getEntityAnnualPatientRegistration('numberOfArrests') ? getEntityAnnualPatientRegistration('numberOfArrests') : 0}
									onValueChanged={(e) => onChangeFunc('NumberOfArrests', e)}
									showSpinButtons={true}
									min={0}
									className="label__medium"
									labelMode="floating"
									stylingMode="outlined" />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>Health and Treatment Information:</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="If Female, Are You Pregnant">
							<>
								<SelectBox
									label="If Female, Are You Pregnant"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityAnnualPatientRegistration('pregnant')}
									dataSource={pregnantStatuses}
									onValueChanged={(e) => onChangeFunc('Pregnant', e)}
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-pregnantStatus' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<CheckboxGrid item xs={GRID_XS}>
						<Tooltip title="Are you an IV Drug User?">
							<>
								<CheckBox
									text="Are you an IV Drug User?"
									value={getEntityAnnualPatientRegistration('drugUser')}
									onValueChanged={() => onChangeFunc('DrugUser')} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Have you EVER been in the hospital or residential treatment for substance abuse or mental health reasons?">
							<>
								<SelectBox
									label="Have you EVER been in the hospital or residential treatment for substance abuse or mental health reasons?"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityAnnualPatientRegistration('haveEverBeenInHospital')}
									dataSource={hospitalResidentialTreatment}
									onValueChanged={(e) => onChangeFunc('HaveEverBeenInHospital', e)}
									className="label__long"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-haveEverBeenInHospital' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Have you been in the hospital in the last 6 months for medical problems cause by substance abuse?">
							<>
								<SelectBox
									label="Have you been in the hospital in the last 6 months for medical problems cause by substance abuse?"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityAnnualPatientRegistration('haveBeenInHospitalLast6Months')}
									dataSource={hospitalInSixMonths}
									onValueChanged={(e) => onChangeFunc('HaveBeenInHospitalLast6Months', e)}
									className="label__long"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-haveBeenInHospitalLast6Months' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Have you had any significant illness or injury in the past 12 months?">
							<>
								<SelectBox
									label="Have you had any significant illness or injury in the past 12 months?"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityAnnualPatientRegistration('haveHadIllnessOrInjury')}
									dataSource={illnessInjury12months}
									onValueChanged={(e) => onChangeFunc('HaveHadIllnessOrInjury', e)}
									className="label__long"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-haveHadIllnessOrInjury' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Date of your last Physical Exam?">
							<>
								<DateBox
									label="Date of your last Physical Exam?"
									type="date"
									value={dateFormatFromApi(getEntityAnnualPatientRegistration('physicalExam'))}
									onValueChanged={(e) => onChangeFunc('PhysicalExam', e)}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									className="label__medium"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RangeRule
											message="Date cannot be earlier than 1900 and later than current date"
											min={MIN_DATE}
											max={CURRENT_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<CheckboxGrid item xs={GRID_XS}>
						<Tooltip title="Would you like to be contacted by the Medical RN or like a referral for a physical exam?">
							<>
								<CheckBox
									text="Would you like to be contacted by the Medical RN or like a referral for a physical exam?"
									value={getEntityAnnualPatientRegistration('contactedByMedicalRN')}
									onValueChanged={() => onChangeFunc('ContactedByMedicalRN')} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Do you have an Advance Health Care Directive, also known as a living will, Durable Power of Attorney, POLST or Comfort One?">
							<>
								<SelectBox
									label="Do you have an Advance Health Care Directive, also known as a living will, Durable Power of Attorney, POLST or Comfort One?"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityAnnualPatientRegistration('livingWill')}
									dataSource={advHCareDirectiveStatuses}
									onValueChanged={(e) => onChangeFunc('LivingWill', e)}
									validationMessagePosition="bottom"
									className="label__long"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-advHCareDirectiveStatus' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							<b>Consent: </b>
							I hereby certify that all information provided is true and correct to the best of my knowledge and I give my consent for APIA to administer assessments and/or treatments to perform behavioral health or medical services. APIA operates as an integrated health system and may refer patients as necessary.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							I authorize APIA Behavioral Health Providers to disclose general mental health, housing, drug or alcohol use information to APIA staff for the purpose of program evaluation and grant reporting. I understand I may be contacted to complete a questionnaire over the telephone or by email. I understand that I may revoke this authorization at any time preventing future use. Unless I revoke my consent earlier, this consent will expire automatically one year from active services at APIA.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							<b>Assignment of Medical Insurance Benefits: </b>
							I hereby agree to full responsibility for all expenses incurred by or on account of this patient and assign to APIA, Inc. any and all insurance benefits due to me to the full extent of my financial obligation and said providers. This is certification and authorization is valid until otherwise revoking in writing.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							I consent to full financial responsibility for services rendered at APIA, Inc.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Date *">
							<>
								<DateBox
									label="Date *"
									type="date"
									value={dateFormatFromApi(getEntityAnnualPatientRegistration('dateConsent'))}
									onValueChanged={(e) => onChangeFunc('DateConsent', e)}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined">
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Date is required" />
										<RangeRule
											message="Date cannot be earlier or later than that year"
											min={MIN_CURRENT_DATE}
											max={MAX_CURRENT_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="contained"
							validationGroup={sectionName}
							onClick={onSaveAnnualPatientRegistration} />
					</Grid>
				</Grid>
			}
		</Wrapper>
	);
}