import { Wrapper, Grid, Item, Label, TextBox, DateBox, SelectBox, CheckBox, Buttons, CheckBoxWrapper } from '../../BasicInformation.style';
import { ADVANCE_CARE, CURRENT_DATE, MAX_WIDTH, MIN_DATE, GRID_XS, GRID_SM, GRID_LG } from '../../../../../../utils/constants';
import { dateFormatFromApi } from '../../../../../../utils/formats';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RangeRule,
} from 'devextreme-react/validator';

export const AdvanceCare = ({
	loading,
	showButton,

	advHCareDirectiveStatuses,
	advPsychDirectiveStatuses,

	getEntityPatientProfileAdvanceCare,
	onChangeFunc,

	onCancelAdvanceCare,
	onSaveAdvanceCare,
}) => {
	return (
		<Wrapper id={ADVANCE_CARE} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityPatientProfileAdvanceCare('loading')}
				position={{ of: `#${ADVANCE_CARE}` }} />
			{
				!getEntityPatientProfileAdvanceCare('loading') &&
				<Grid container spacing={2}>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="I have an Advance Health Care Directive, also known as a living will, Durable Power of Attorney, POLST or Comfort One">
							<Label>Advance Health Care Directive:</Label>
						</Tooltip>
						<SelectBox
							label="I have an Advance Health Care Directive, also known as a living will, Durable Power of Attorney, POLST or Comfort One"
							valueExpr="Oid"
							displayExpr="Name"
							defaultValue={getEntityPatientProfileAdvanceCare('haveLivingWill')}
							dataSource={advHCareDirectiveStatuses}
							onValueChanged={(e) => onChangeFunc('HaveLivingWill', e)}
							showDropDownButton={false}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-haveLivingWill' }}
						/>
					</Item>
					{
						getEntityPatientProfileAdvanceCare('haveLivingWill') === (advHCareDirectiveStatuses.find((e) => e?.Name === "Unable to Access")?.Oid) &&
						<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
							<Tooltip title="Reason">
								<Label>Reason:</Label>
							</Tooltip>
							<TextBox
								label="Reason"
								value={getEntityPatientProfileAdvanceCare('haveLivingWillReason')}
								onValueChanged={(e) => onChangeFunc('HaveLivingWillReason', e, true)}
								maxLength={30}
								labelMode="hidden"
								stylingMode="underlined"
								inputAttr={{ autocomplete: 'new-haveLivingWillReason' }} />
						</Item>
					}
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="I have a Psychiatric Advance Directive">
							<Label>Psychiatric Advance Directive:</Label>
						</Tooltip>
						<SelectBox
							label="I have a Psychiatric Advance Directive"
							valueExpr="Oid"
							displayExpr="Name"
							defaultValue={getEntityPatientProfileAdvanceCare('psychiatricAdvanceDirective')}
							dataSource={advPsychDirectiveStatuses}
							onValueChanged={(e) => onChangeFunc('PsychiatricAdvanceDirective', e)}
							showDropDownButton={false}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-psychiatricAdvanceDirective' }}
						/>
					</Item>
					{
						getEntityPatientProfileAdvanceCare('psychiatricAdvanceDirective') === (advPsychDirectiveStatuses.find((e) => e?.Name === "Unable to Access")?.Oid) &&
						<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
							<Tooltip title="Reason">
								<Label>Reason:</Label>
							</Tooltip>
							<TextBox
								label="Reason"
								value={getEntityPatientProfileAdvanceCare('psychiatricAdvanceDirectiveReason')}
								onValueChanged={(e) => onChangeFunc('PsychiatricAdvanceDirectiveReason', e, true)}
								maxLength={30}
								labelMode="hidden"
								stylingMode="underlined"
								inputAttr={{ autocomplete: 'new-psychiatricAdvanceDirectiveReason' }} />
						</Item>
					}
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Info about Directives needed">
							<Label>Info about Directives needed:</Label>
						</Tooltip>
						<CheckBoxWrapper>
							<CheckBox
								value={getEntityPatientProfileAdvanceCare('advanceHealthCareDirectiveInfo')}
								onClick={() => onChangeFunc('AdvanceHealthCareDirectiveInfo')} >
								{getEntityPatientProfileAdvanceCare('advanceHealthCareDirectiveInfo') ? 'Yes' : 'No'}
							</CheckBox>
						</CheckBoxWrapper>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Directive on file">
							<Label>Directive on file:</Label>
						</Tooltip>
						<CheckBoxWrapper>
							<CheckBox
								value={getEntityPatientProfileAdvanceCare('advanceHealthCareDirectiveOnFile')}
								onClick={() => onChangeFunc('AdvanceHealthCareDirectiveOnFile')} >
								{getEntityPatientProfileAdvanceCare('advanceHealthCareDirectiveOnFile') ? 'Yes' : 'No'}
							</CheckBox>
						</CheckBoxWrapper>
					</Item>
					{
						getEntityPatientProfileAdvanceCare('advanceHealthCareDirectiveOnFile') &&
						<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
							<Tooltip title="Last date reviewed">
								<Label>Last Date Reviewed:</Label>
							</Tooltip>
							<DateBox
								type="date"
								label="Last date reviewed"
								value={dateFormatFromApi(getEntityPatientProfileAdvanceCare('lastDateReview'))}
								onValueChanged={(e) => onChangeFunc('LastDateReview', e)}
								showDropDownButton={false}
								useMaskBehavior={true}
								displayFormat="MM/dd/yyyy"
								invalidDateMessage="The date must have the following format: MM/dd/yyyy"
								labelMode="hidden"
								stylingMode="underlined"
							>
								<Validator validationGroup={ADVANCE_CARE}>
									<RangeRule
										message="Last date reviewed cannot be later than the current date and earlier than 1900"
										min={MIN_DATE}
										max={CURRENT_DATE} />
								</Validator>
							</DateBox>
						</Item>
					}
					<Item item sm={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={ADVANCE_CARE}
								onClick={onSaveAdvanceCare} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelAdvanceCare} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper >
	);
};