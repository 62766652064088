import { Wrapper, Item, Label, TextBox, DateBox, SelectBox, CheckBox, Buttons } from '../../BasicInformation.style';
//import { CURRENT_DATE, MAX_DATE, MAX_WIDTH, MIN_DATE, MAX_DOB, TEXT_DOB, INSURANCE_INFO, PHONE_MASK, ZIP_MASK, GRID_XS } from '../../../../../../utils/constants';
import { MAX_DATE, MAX_WIDTH, MIN_DATE, INSURANCE_INFO, PHONE_MASK, ZIP_MASK, GRID_XS } from '../../../../../../utils/constants';
import { dateFormatFromApi } from '../../../../../../utils/formats';
import { NAME_RULE } from '../../../../../../utils/rules';
import { onKeyDownHandle } from '../../../../../../utils/helper';
import { useResizeDetector } from 'react-resize-detector';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import DataGrid, { Column, Editing, Toolbar, Item as ItemToolbar } from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	PatternRule,
	RangeRule,
	RequiredRule
} from 'devextreme-react/validator';

const setSizeByWidth = (width) => {
	switch (true) {
		case (width <= 600): return 12;
		case (width > 600 && width <= 900): return 6;
		case (width > 900 && width <= 1200): return 4;
		case (width > 1200): return 3;
		default: return 12;
	};
};

export const InsuranceInfo = ({
	showButton,

	totalHouseholdIncomes,
	denaliKidStatuses,
	medicaidStatuses,
	medicareStatuses,
	states,

	getEntityPatientProfileInsuranceInfo,

	onRowInsertingFamilyMembers,
	onRowRemovingFamilyMembers,
	onRowUpdatingFamilyMembers,

	onChangeFunc,

	onCancelInsuranceInfo,
	onSaveInsuranceInfo,
}) => {
	const { width, ref } = useResizeDetector();
	const [size, setSize] = useState(12);

	useEffect(() => {
		setSize(setSizeByWidth(width));
	}, [width]);

	return (
		< Wrapper id={INSURANCE_INFO} ref={ref} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityPatientProfileInsuranceInfo('loading')}
				position={{ of: `#${INSURANCE_INFO}` }} />
			{
				!getEntityPatientProfileInsuranceInfo('loading') &&
				<Grid container spacing={2}>
					<Item item xs={12} style={{ paddingBottom: '10px' }}>
						<DataGrid
							showBorders={true}
							dataSource={getEntityPatientProfileInsuranceInfo('allMembers')}
							onRowInserting={onRowInsertingFamilyMembers}
							onRowRemoving={onRowRemovingFamilyMembers}
							onRowUpdating={onRowUpdatingFamilyMembers}>
							<Toolbar>
								<ItemToolbar location="before" name='addRowButton' showText />
							</Toolbar>
							<Editing
								mode="row"
								allowAdding={true}
								allowUpdating={true}
								allowDeleting={true}
								confirmDelete={false} />
							<Column
								dataField="name"
								caption="Full Name"
								alignment="left" >
								<RequiredRule />
							</Column>
							<Column
								dataField="relationship"
								caption="Relationship"
								alignment="left" />
							<Column
								dataField="age"
								caption="Age"
								dataType="number"
								alignment="left"
								editorOptions={{
									min: 0,
									max: 120,
									format: 'fixedPoint'
								}} />
						</DataGrid>
					</Item>
					<Item item xs={12}>
						<Tooltip title="Total Annual Household Income Estimate">
							<span>
								<Label>Total Annual Household Income Estimate:</Label>
								<SelectBox
									label="Total Annual Household Income Estimate"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityPatientProfileInsuranceInfo('totalIncome')}
									dataSource={totalHouseholdIncomes}
									onValueChanged={(e) => onChangeFunc('Income', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-totalHouseholdIncome' }}
								/>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Medicare">
							<span>
								<Label>Medicare:</Label>
								<SelectBox
									label="Medicare"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityPatientProfileInsuranceInfo('medicare')}
									dataSource={medicareStatuses}
									onValueChanged={(e) => onChangeFunc('Medicare', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-medicareStatus' }}
								/>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Effective date">
							<span>
								<Label>Effective date:</Label>
								<DateBox
									type="date"
									label="Effective date"
									value={dateFormatFromApi(getEntityPatientProfileInsuranceInfo('medicareEffDate'))}
									onValueChanged={(e) => onChangeFunc('MedicareEffDate', e)}
									showDropDownButton={false}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="hidden"
									stylingMode="underlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Effective Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Exp Date">
							<span>
								<Label>Exp Date:</Label>
								<DateBox
									type="date"
									label="Exp Date"
									value={dateFormatFromApi(getEntityPatientProfileInsuranceInfo('medicareExpDate'))}
									onValueChanged={(e) => onChangeFunc('MedicareExpDate', e)}
									showDropDownButton={false}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="hidden"
									stylingMode="underlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Exp Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="#">
							<span>
								<Label>#</Label>
								<TextBox
									label="#"
									value={getEntityPatientProfileInsuranceInfo('medicareNumber')}
									onValueChanged={(e) => onChangeFunc('MedicareNumber', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-medicareNumber' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Medicaid">
							<span>
								<Label>Medicaid:</Label>
								<SelectBox
									label="Medicaid"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityPatientProfileInsuranceInfo('medicaid')}
									dataSource={medicaidStatuses}
									onValueChanged={(e) => onChangeFunc('Medicaid', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-medicaidStatus' }}
								/>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Effective date">
							<span>
								<Label>Effective date:</Label>
								<DateBox
									type="date"
									label="Effective date"
									defaultValue={dateFormatFromApi(getEntityPatientProfileInsuranceInfo('medicaidEffDate'))}
									onValueChanged={(e) => onChangeFunc('MedicaidEffDate', e)}
									showDropDownButton={false}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="hidden"
									stylingMode="underlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Effective Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Exp Date">
							<span>
								<Label>Exp Date:</Label>
								<DateBox
									type="date"
									label="Exp Date"
									value={dateFormatFromApi(getEntityPatientProfileInsuranceInfo('medicaidExpDate'))}
									onValueChanged={(e) => onChangeFunc('MedicaidExpDate', e)}
									showDropDownButton={false}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="hidden"
									stylingMode="underlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Exp Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="#">
							<span>
								<Label>#</Label>
								<TextBox
									label="#"
									value={getEntityPatientProfileInsuranceInfo('medicaidNumber')}
									onValueChanged={(e) => onChangeFunc('MedicaidNumber', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-medicaidNumber' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Denali Kid">
							<span>
								<Label>Denali Kid:</Label>
								<SelectBox
									label="Denali Kid"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityPatientProfileInsuranceInfo('denaliKid')}
									dataSource={denaliKidStatuses}
									onValueChanged={(e) => onChangeFunc('DenaliKid', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-denaliKidStatus' }}
								/>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Effective date">
							<span>
								<Label>Effective date:</Label>
								<DateBox
									type="date"
									label="Effective date"
									value={dateFormatFromApi(getEntityPatientProfileInsuranceInfo('denaliKidEffDate'))}
									onValueChanged={(e) => onChangeFunc('DenaliKidEffDate', e)}
									showDropDownButton={false}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="hidden"
									stylingMode="underlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Effective Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Exp Date">
							<span>
								<Label>Exp Date:</Label>
								<DateBox
									type="date"
									label="Exp Date"
									value={dateFormatFromApi(getEntityPatientProfileInsuranceInfo('denaliKidExpDate'))}
									onValueChanged={(e) => onChangeFunc('DenaliKidExpDate', e)}
									showDropDownButton={false}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="hidden"
									stylingMode="underlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Exp Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="#">
							<span>
								<Label>#</Label>
								<TextBox
									label="#"
									value={getEntityPatientProfileInsuranceInfo('denaliKidNumber')}
									onValueChanged={(e) => onChangeFunc('DenaliKidNumber', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-denaliKidNumber' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Private Insurance">
							<span>
								<Label>Private Insurance: </Label>
								<CheckBox
									value={getEntityPatientProfileInsuranceInfo('privateInsurance')}
									onClick={() => onChangeFunc('PrivateInsurance')} >
									{getEntityPatientProfileInsuranceInfo('privateInsurance') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Insurance Company">
							<span>
								<Label>Insurance Company:</Label>
								<TextBox
									label="Insurance Company"
									value={getEntityPatientProfileInsuranceInfo('companyInsurance')}
									onValueChanged={(e) => onChangeFunc('CompanyInsurance', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-companyInsurance' }}
								/>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="I DO NOT have other insurance coverage">
							<span>
								<Label>I DO NOT have other insurance coverage: </Label>
								<CheckBox
									value={getEntityPatientProfileInsuranceInfo('doNotOtherInsurance')}
									onClick={() => onChangeFunc('DoNotOtherInsurance')} >
									{getEntityPatientProfileInsuranceInfo('doNotOtherInsurance') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip  title="Policy Holder">
							<span>
								<Label>Policy Holder:</Label>
								<TextBox
									label="Policy Holder"
									value={getEntityPatientProfileInsuranceInfo('policyHolder')}
									onValueChanged={(e) => onChangeFunc('PolicyHolder', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-policyHolder' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Policy #">
							<span>
								<Label>Policy1 #</Label>
								<TextBox
									label="Policy #"
									value={getEntityPatientProfileInsuranceInfo('policyHolderNumber')}
									onValueChanged={(e) => onChangeFunc('PolicyHolderNumber', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-policyHolderNumber' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Group #">
							<span>
								<Label>Group #</Label>
								<TextBox
									label="Group #"
									value={getEntityPatientProfileInsuranceInfo('policyHolderGroupNumber')}
									onValueChanged={(e) => onChangeFunc('PolicyHolderGroupNumber', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-policyHolderGroupNumber' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Other Insurance">
							<span>
								<Label>Other Insurance</Label>
								<TextBox
									label="Other Insurance"
									value={getEntityPatientProfileInsuranceInfo('otherInsurance')}
									onValueChanged={(e) => onChangeFunc('OtherInsurance', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-otherInsurance' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Policy #">
							<span>
								<Label>Policy #</Label>
								<TextBox
									label="Policy #"
									value={getEntityPatientProfileInsuranceInfo('otherInsuranceGroupNumber')}
									onValueChanged={(e) => onChangeFunc('OtherInsuranceGroupNumber', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-otherInsuranceNumber' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Group #">
							<span>
								<Label>Group #</Label>
								<TextBox
									label="Group #"
									value={getEntityPatientProfileInsuranceInfo('otherInsuranceGroupNumber')}
									onValueChanged={(e) => onChangeFunc('OtherInsuranceGroupNumber', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-otherInsuranceGroupNumber' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Service Branch">
							<span>
								<Label>Service Branch:</Label>
								<TextBox
									label="Service Branch"
									value={getEntityPatientProfileInsuranceInfo('serviceBranch')}
									onValueChanged={(e) => onChangeFunc('ServiceBranch', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-serviceBranch' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Service Entry Date">
							<span>
								<Label>Service Entry Date:</Label>
								<DateBox
									type="date"
									label="Service Entry Date"
									value={dateFormatFromApi(getEntityPatientProfileInsuranceInfo('serviceEntryDate'))}
									onValueChanged={(e) => onChangeFunc('ServiceEntryDate', e)}
									showDropDownButton={false}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="hidden"
									stylingMode="underlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Service Entry Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Service Exit Date">
							<span>
								<Label>Service Exit Date:</Label>
								<DateBox
									type="date"
									label="Service Exit Date"
									value={dateFormatFromApi(getEntityPatientProfileInsuranceInfo('serviceExitDate'))}
									onValueChanged={(e) => onChangeFunc('ServiceExitDate', e)}
									showDropDownButton={false}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="hidden"
									stylingMode="underlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Service Exit Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Veteran">
							<span>
								<Label>Veteran: </Label>
								<CheckBox
									value={getEntityPatientProfileInsuranceInfo('veteran')}
									onClick={() => onChangeFunc('Veteran')} >
									{getEntityPatientProfileInsuranceInfo('veteran') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="VA Benefits">
							<span>
								<Label>VA Benefits: </Label>
								<CheckBox
									value={getEntityPatientProfileInsuranceInfo('benefitsVA')}
									onClick={() => onChangeFunc('BenefitsVA')} >
									{getEntityPatientProfileInsuranceInfo('benefitsVA') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Vietnam Service Indicated">
							<span>
								<Label>Vietnam Service Indicated: </Label>
								<CheckBox
									value={getEntityPatientProfileInsuranceInfo('vietnamService')}
									onClick={() => onChangeFunc('VietnamService')} >
									{getEntityPatientProfileInsuranceInfo('vietnamService') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Self Pay/Guarantor">
							<span>
								<Label>Self Pay/Guarantor: </Label>
								<TextBox
									label="Self Pay/Guarantor"
									value={getEntityPatientProfileInsuranceInfo('selfPay')}
									onValueChanged={(e) => onChangeFunc('SelfPay', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-selfPay' }}
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<PatternRule
											message="Do not use digits in the Self Pay/Guarantor"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Relationship">
							<span>
								<Label>Relationship:</Label>
								<TextBox
									label="Relationship"
									value={getEntityPatientProfileInsuranceInfo('relationship')}
									onValueChanged={(e) => onChangeFunc('Relationship', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-relationship' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Date Of Birth">
							<span>
								<Label>Date Of Birth:</Label>
								<DateBox
									type="date"
									label="Date Of Birth"
									value={dateFormatFromApi(getEntityPatientProfileInsuranceInfo('DOB'))}
									onValueChanged={(e) => onChangeFunc('DOB', e)}
									showDropDownButton={false}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="hidden"
									stylingMode="underlined"
								>
									{/* <Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Date of Birth cannot be younger than the current date and earlier than 1900"
											min={MIN_DATE}
											max={CURRENT_DATE} />
										<RangeRule
											message={TEXT_DOB}
											max={MAX_DOB} />
									</Validator> */}
								</DateBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Phone">
							<span>
								<Label>Phone: </Label>
								<TextBox
									label="Phone"
									value={getEntityPatientProfileInsuranceInfo('phone')}
									onValueChanged={(e) => onChangeFunc('Phone', e)}
									labelMode="hidden"
									stylingMode="underlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Effective Date">
							<span>
								<Label>Effective Date: </Label>
								<DateBox
									type="date"
									label="Effective Date"
									value={dateFormatFromApi(getEntityPatientProfileInsuranceInfo('effectiveDate'))}
									onValueChanged={(e) => onChangeFunc('EffectiveDate', e)}
									showDropDownButton={false}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="hidden"
									stylingMode="underlined"
								>
									<Validator validationGroup={INSURANCE_INFO}>
										<RangeRule
											message="Effective Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Address">
							<span>
								<Label>Address: </Label>
								<TextBox
									label="Address"
									value={getEntityPatientProfileInsuranceInfo('address')}
									onValueChanged={(e) => onChangeFunc('Address', e, true)}
									maxLength={150}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-address' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="State">
							<span>
								<Label>State: </Label>
								<SelectBox
									label="State"
									defaultValue={getEntityPatientProfileInsuranceInfo('state')}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={(e) => onChangeFunc('State', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-state' }}
								/>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="City">
							<span>
								<Label>City:</Label>
								<TextBox
									label="City"
									value={getEntityPatientProfileInsuranceInfo('city')}
									onValueChanged={(e) => onChangeFunc('City', e, true)}
									maxLength={20}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-city' }}>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Zip">
							<span>
								<Label>Zip: </Label>
								<TextBox
									label="Zip"
									value={getEntityPatientProfileInsuranceInfo('zip')}
									onValueChanged={(e) => onChangeFunc('Zip', e)}
									labelMode="hidden"
									stylingMode="underlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={ZIP_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="Zip must have at least 5 symbols"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-zip' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item sm={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={INSURANCE_INFO}
								onClick={onSaveInsuranceInfo} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelInsuranceInfo} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper >
	);
};